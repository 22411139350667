import { gql } from "@apollo/client";
export const CREATE_USER_MUTATION = gql`
  mutation CreateUser(
    $username: String!
    $name: String!
    $email: String!
    $role: String!
  ) {
    createUser(
      createUserInput: {
        username: $username
        name: $name
        email: $email
        role: $role
      }
    ) {
      _id
      name
      email
      username
      role
      status
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_USER_MUTATION = gql`
  mutation RemoveUser($id: String!) {
    removeUser(id: $id) {
      _id
      name
      email
      username
      role
      status
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser($id: String!, $status: Int!) {
    updateUser(updateUserInput: { id: $id, status: $status }) {
      _id
      name
      email
      username
      role
      status
      createdAt
      updatedAt
    }
  }
`;

export const LOGOUT_USER_MUTATION = gql`
  mutation {
    signOut {
      username
    }
  }
`;
