import AuthRepository from "./AuthRepository";
import BankRepository from "./BankRepository";
import UserRepository from "./UserRepository";
import Types from "../utils/constants/repositoryTypes";
import TransactionRepository from "./TransactionRepository";
import WithdrawRepository from "./WithdrawRepository";

const repositories = {
  [Types.AUTH]: AuthRepository,
  [Types.BANK]: BankRepository,
  [Types.USER]: UserRepository,
  [Types.TRANSACTION]: TransactionRepository,
  [Types.WITHDRAW]: WithdrawRepository,
};

export default {
  get: (name) => repositories[name],
};