import { Outlet } from "react-router-dom";

const AuthLayout = () => (
  <div className="flex w-full justify-around h-screen">
    <div className="flex flex-col justify-center items-center h-full w-full shrink text-slate-50 bg-primary-gradient">
      <div className="text-left">
        <h1 className="text-3xl">
          <span className="font-bold text-4xl">Welcome to</span> system portal
        </h1>
        <p>Login to access your account</p>
      </div>
      <div className="w-[500px] overflow-hidden">
        <img
          src="/assets/Refund-amico.svg"
          className=" object-contain w-full h-full"
        />
      </div>
    </div>
    <div className="flex justify-center items-center w-5/6 shrink bg-slate-50">
      <Outlet />
    </div>
  </div>
);

export default AuthLayout;
