import RepositoryFactory from "../repositories/RepositoryFactory";
import Types from "../utils/constants/repositoryTypes";

const api = RepositoryFactory.get(Types.USER);

const UserService = (() => {
    let instance = null;
     const createInstance = () => {
        return {
            get: async (payload) => {
                return api.get(payload);
            },
            create: async (payload) => {
                return api.post(payload);
            },
            delete: async (id) => {
                return api.delete(id);
            },
            toggleLock: async (id) => {
                return api.update(id);
            },
            sendPassword: async (id) => {
                return api.sendPass(id, {newPassword: true});
            },
        }
     }

     return {
        getInstance: () => {
            if(!instance) {
                instance = createInstance();
            }

            return instance;
        }
     }
})();

export default UserService.getInstance();
