import { gql } from "@apollo/client";

export const UPDATE_DEPOSIT_MUTATION = gql`
  mutation UpdateDeposit($id: String!, $action: String!, $status: String!) {
    updateDeposit(
      depositUpdateInput: { id: $id, action: $action, status: $status }
    ) {
      _id
      playerId
      requestedAt
      category
      toAccount
      rib
      fullName
      amount
      status
      actionAt
      action
      createdAt
      updatedAt
    }
  }
`;
