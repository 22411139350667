import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { selectSite } from "store/selectors";
import { setSiteConfigs } from "store/actions/siteConfig";
import { GET_SITE_CONFIG_QUERY } from "utils/gql/queries/site-config.query";

const useSiteConfigs = () => {
  const dispatch = useDispatch();
  const siteConfig = useSelector(selectSite);
  const [configs, setConfigs] = useState(siteConfig);

  const [getSite] = useLazyQuery(GET_SITE_CONFIG_QUERY);

  useEffect(() => {
    if (!Object.keys(siteConfig?.site || {}).length) {
      getSite({
        variables: { id: "" },
      }).then((site) => {
        dispatch(setSiteConfigs(site?.data?.siteConfig));
        setConfigs(site?.data?.siteConfig);
      });
    }
  }, [siteConfig?.site]);

  return { configs: siteConfig };
};

export default useSiteConfigs;
