import { Fragment, useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import useFilter from "utils/hooks/useFilter";
import PageTitle from "components/organisms/PageTitle";
import GridTable from "components/organisms/GridTable";
import { GET_DEPOSITS_QUERY } from "utils/gql/queries/deposit.query";
import {
  ACTION_STATUS,
  DEPOSIT_FILTER_INPUTS,
  DEPOSIT_TABLE_HEADERS,
} from "utils/constants/deposit";
import { UPDATE_DEPOSIT_MUTATION } from "utils/gql/mutations/deposit.mutation";
import { useDispatch } from "react-redux";
import { decrement } from "store/actions/siteConfig";
import { UPDATE_SITE_MUTATION } from "utils/gql/mutations/site-config.mutation";
import useSiteConfigs from "utils/hooks/useSiteConfigs";

const Deposits = () => {
  const dispatch = useDispatch();
  const {
    filterOptions,
    setFilterOptions,
    isLoading: isFilterLoading,
  } = useFilter();
  const { configs } = useSiteConfigs();
  const { loading, error, data, fetchMore } = useQuery(GET_DEPOSITS_QUERY, {
    variables: { filter: { ...filterOptions, page: 1 } },
  });

  const [update, { error: _error, rest }] = useMutation(
    UPDATE_DEPOSIT_MUTATION
  );

  const loadMore = () => {
    if (data?.deposits?.currentPage >= data?.deposits?.totalPages) return;
    fetchMore({
      variables: {
        filter: {
          ...filterOptions,
          page: data?.deposits?.currentPage + 1,
        },
      },
    });
  };

  const [updateSite] = useMutation(UPDATE_SITE_MUTATION);

  const handleAction = async (id, action) => {
    try {
      await update({ variables: { id, action, status: action } });
      dispatch(decrement());
      updateSite({ variables: { id: "id" } });
    } catch (e) {}
  };

  return (
    <Fragment>
      <PageTitle
        title="Deposits"
        setFilter={setFilterOptions}
        filters={DEPOSIT_FILTER_INPUTS}
      />
      {!loading && (
        <GridTable
          loadMore={loadMore}
          hasMoreItems={
            data?.deposits?.currentPage < data?.deposits?.totalPages
          }
          headers={DEPOSIT_TABLE_HEADERS}
          dataKeys={[
            "playerId",
            "requestedAt",
            "category",
            "toAccount",
            "rib",
            "fullName",
            "amount",
            // "status",
            "actionAt",
            "createdAt",
            "updatedAt",
            "action",
          ]}
          dateFormated={["requestedAt", "actionAt", "createdAt", "updatedAt"]}
          actions={
            configs?.gas > 0
              ? [
                  {
                    action: ({ id }) => handleAction(id, ACTION_STATUS.PAID),
                    component: CheckIcon,
                  },
                  {
                    action: ({ id }) =>
                      handleAction(id, ACTION_STATUS.DECLINED),
                    component: XMarkIcon,
                    className:
                      "!text-red-500 hover:text-red-800 cursor-pointer",
                  },
                ]
              : []
          }
          data={data?.deposits?.data || []}
        />
      )}
    </Fragment>
  );
};

export default Deposits;
