import { gql } from "@apollo/client";

export const UPDATE_SITE_MUTATION = gql`
  mutation UpdateSite($id: String!) {
    updateSite(siteUpdateInput: { id: $id }) {
      _id
      name
      callbackUrl
      gas
      requestPrice
    }
  }
`;
