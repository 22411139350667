import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Form from "../FormV1";
import InputGroup from "components/molecules/InputGroup";
import { addBankSchema } from "utils/validations/bank-validations";
import { ADD_BANK_INPUTS } from "utils/constants/bank-settings";

const AddBankFormTemplate = ({
  onSubmit,
  buttonContent,
  defaultValues,
  options,
  fileRef,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(addBankSchema),
    defaultValues,
  });

  return (
    <Form buttonContent={buttonContent} onSubmit={handleSubmit(onSubmit)}>
      {ADD_BANK_INPUTS.map((input) => (
        <InputGroup
          control={control}
          id={input.id}
          type={input.type}
          key={input.name}
          name={input.name}
          label={input.label || ""}
          placeholder={input.placeholder || ""}
          component={input.component}
          error={errors[input.name]}
          title={input.title}
          classes={input.classes}
          {...(input.type === "file" ? { fileRef: fileRef } : {})}
          {...(input.options && input.type === "select"
            ? {
                options: options[input.name] || [],
              }
            : {})}
          {...(input.customOption && input.type === "select"
            ? { CustomOption: input.customOption }
            : {})}
          {...(input.type === "select" ? { isMulti: input.isMulti } : {})}
        />
      ))}
    </Form>
  );
};

export default AddBankFormTemplate;
