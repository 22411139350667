import { Fragment } from "react";
import { useQuery } from "@apollo/client";

import GridTable from "../components/organisms/GridTable";
import PageTitle from "../components/organisms/PageTitle";

import useFilter from "utils/hooks/useFilter";

import {
  DASHBOARD_FILTER_INPUTS,
  DASHBOARD_TABLE_HEADERS,
} from "../utils/constants/dashboard";
import { GET_SUCCESS_PAID_DEPOSITS_QUERY } from "utils/gql/queries/deposit.query";

const Dashboard = () => {
  const {
    filterOptions,
    setFilterOptions,
    isLoading: isFilterLoading,
  } = useFilter();

  const { loading, error, data, fetchMore } = useQuery(
    GET_SUCCESS_PAID_DEPOSITS_QUERY,
    {
      variables: { filter: { ...filterOptions, page: 1 } },
    }
  );

  const loadMore = () => {
    if (
      data?.statisticDeposits?.currentPage >=
      data?.statisticDeposits?.totalPages
    )
      return;
    fetchMore({
      variables: {
        filter: {
          ...filterOptions,
          page: data?.statisticDeposits?.currentPage + 1,
        },
      },
    });
  };

  return (
    <Fragment>
      <PageTitle title="Dashboard" filters={DASHBOARD_FILTER_INPUTS} />
      <GridTable
        loadMore={loadMore}
        hasMoreItems={
          data?.statisticDeposits?.currentPage <
          data?.statisticDeposits?.totalPages
        }
        headers={DASHBOARD_TABLE_HEADERS}
        dataKeys={["", "count", "amount", "action", "date"]}
        dateFormated={["date"]}
        data={data?.statisticDeposits?.data || []}
      />
    </Fragment>
  );
};

export default Dashboard;

// Gnman iravunqov

// Es esinch esinchyans knojs esinch esinchyanin (andznagri hamare) talis em im hamadzaynutyune gnman ev gravadrman iravunqov dzerq beri esinch hasceum gtnvox bnakarane
