import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  totalPages: null,
  currentPage: null,
  perPage: null,
  total: null,
  deposits: [],
};

const depositSlice = createSlice({
  name: "deposit",
  initialState,
  reducers: (create) => ({
    setDeposits: create.preparedReducer(
      (payload) => payload,
      (state, action) => {
        state.deposits = action.payload;
      }
    ),
  }),
  selectors: {
    getDeposits: (sliceState) => sliceState.deposits,
  },
});

export const { setDeposits } = depositSlice.actions;
// export const { getDeposits } = depositSlice.selectors;
export default depositSlice.reducer;
