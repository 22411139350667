import { components } from "react-select";

const CustomAccountNumberOption = (props) => {
  const { data, placeholder } = props;
  return !Object.keys(data).length ? (
    <components.Option
      {...props}
      className="cursor-pointer px-10 py-1 m-0 last:border-0"
    >
      <div className="flex content-medium py-2">
        <span className="pr-1">No options</span>
      </div>
    </components.Option>
  ) : (
    <components.Option
      {...props}
      key={`${data.code}`}
      className="px-10 py-1 m-0 cursor-pointer text-[#23242C]"
    >
      {data.label}
      <div className="text-gray-500 text-[11px]">{data.account}</div>
    </components.Option>
  );
};

export default CustomAccountNumberOption;
