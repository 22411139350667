import { Fragment } from "react";
import { useGlobalModalContext } from "../components/organisms/GlobalModal";
import GridTable from "../components/organisms/GridTable";
import PageTitle from "../components/organisms/PageTitle";
import WithdrawService from "../services/WithdrawService";
import { MODAL_TYPES } from "../utils/constants/modals";
import {
  WITHDRAW_FILTER_INPUTS,
  WITHDRAW_TABLE_HEADERS,
} from "../utils/constants/withdraw";
import useFilter from "../utils/hooks/useFilter";
import useRequest from "../utils/hooks/useRequest";

const Withdrawals = () => {
  const { showModal } = useGlobalModalContext();
  const {
    filterOptions,
    setFilterOptions,
    isLoading: isFilterLoading,
  } = useFilter();
  // const { result, isLoading } = useRequest(
  //   () => WithdrawService.get(filterOptions),
  //   [filterOptions],
  //   isFilterLoading
  // );

  const _showModal = (id) => {
    showModal(MODAL_TYPES.SELECT_BANK_MODAL, {
      title: "Select Bank",
      confirmBtn: "Pay",
      id,
      selectBank: WithdrawService.selectBank,
    });
  };

  return (
    <Fragment>
      <PageTitle
        title="Withdrawals"
        filters={WITHDRAW_FILTER_INPUTS}
        setFilter={setFilterOptions}
        actions={[
          <button
            className="border cursor-pointer py-1 px-5 rounded shadow-lg hover:shadow-gray-500 transition-shadow border-primary-500 bg-primary-500 text-white"
            onClick={_showModal}
          >
            Select Bank
          </button>,
        ]}
      />
      <GridTable
        headers={WITHDRAW_TABLE_HEADERS}
        data={
          [
            // [
            //   "123456",
            //   "Test Bank",
            //   "123456",
            //   "User",
            //   "User",
            //   "500 KRW",
            //   "2023.03.07 12:35",
            //   <input
            //     id="default-checkbox"
            //     type="checkbox"
            //     value=""
            //     className="w-4 h-4 text-primary-600 bg-gray-100 border-gray-300 rounded focus:ring-primary-500 dark:focus:ring-primary-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            //   />,
            // ],
          ]
        }
      />
    </Fragment>
  );
};

export default Withdrawals;
