import { Fragment } from "react";
import GridTable from "../components/organisms/GridTable";
import PageTitle from "../components/organisms/PageTitle";
import {
  ArrowPathRoundedSquareIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/outline";
import {
  TRANSACTIONS_TABLE_HEADERS,
  TRANSACTION_FILTER_INPUTS,
} from "../utils/constants/transactions";
import TransactionService from "../services/TransactionService";
import useFilter from "../utils/hooks/useFilter";
import useRequest from "../utils/hooks/useRequest";

const Transactions = () => {
  const {
    filterOptions,
    setFilterOptions,
    isLoading: isFilterLoading,
  } = useFilter();
  // const { result, isLoading } = useRequest(
  //   () => TransactionService.get(filterOptions),
  //   [filterOptions],
  //   isFilterLoading
  // );

  const block = async (id, payload) => {
    await TransactionService.block(id, payload);
  };

  const resend = async (id) => {
    await TransactionService.resend(id);
  };

  return (
    <Fragment>
      <PageTitle
        title="Transactions"
        filters={TRANSACTION_FILTER_INPUTS}
        setFilter={setFilterOptions}
      />
      <GridTable
        headers={TRANSACTIONS_TABLE_HEADERS}
        data={
          [
            // [
            //   "test",
            //   "test",
            //   "test",
            //   "test",
            //   "test",
            //   "test",
            //   "test",
            //   "test",
            //   "test",
            //   "test",
            //   "test",
            //   "test",
            //   "test",
            //   "test",
            //   "test",
            //   "test",
            //   "test",
            //   "test",
            //   <MinusCircleIcon
            //     onClick={() => block(1, { isBlocked: true })}
            //     className="h-6 w-6 text-red-500 cursor-pointer"
            //   />,
            //   <ArrowPathRoundedSquareIcon
            //     onClick={() => resend(1)}
            //     className="h-6 w-6 text-gray-500 cursor-pointer"
            //   />,
            // ],
          ]
        }
      />
    </Fragment>
  );
};

export default Transactions;
