import Client from "./clients";
import Types from "../utils/constants/repositoryTypes";
import MainRepository from "./MainRepository";
const resource = Types.TRANSACTION;

export default {
    ...MainRepository(resource),
    resend: (id) => {
        return Client.post(`${resource}/${id}/resend`);
    }
}