import { Fragment, useEffect } from "react";
import { useState, memo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../utils/constants/routes";
import { useMutation } from "@apollo/client";
import { LOGOUT_USER_MUTATION } from "utils/gql/mutations/user.mutation";
import BrandIcon from "components/atoms/BrandIcon";
// import { useSelector } from "react-redux";
// import { selectSite } from "store/selectors";
import useSiteConfigs from "utils/hooks/useSiteConfigs";
import { MODAL_TYPES } from "utils/constants/modals";
import { useGlobalModalContext } from "./GlobalModal";

const TopNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { configs } = useSiteConfigs();
  const { showModal } = useGlobalModalContext();
  // const siteConfig = useSelector(selectSite);
  const [mobileMenuClosed, setMobileMenuClosed] = useState(true);

  const [logout] = useMutation(LOGOUT_USER_MUTATION);

  const logOut = async () => {
    await logout();
    localStorage.removeItem("user");
    navigate("/login", { replace: true });
  };

  const _showLowBalanceModal = () => {
    showModal(MODAL_TYPES.LOW_GAS_MODAL, {});
  };

  useEffect(() => {
    if (configs?.gas < configs?.requestPrice) {
      _showLowBalanceModal();
    }
  }, [configs?.gas]);

  return (
    <header className="fixed w-full bg-white">
      <nav className="border-gray-200 py-2.5 dark:bg-gray-900">
        <div className="flex flex-wrap items-center justify-between max-w-screen-2xl px-11 mx-auto">
          <div className="flex items-center gap-4">
            <a href="/" className="flex items-center">
              <span className="self-center text-xl font-semibold whitespace-nowrap dark:text-white">
                <BrandIcon />
              </span>
            </a>
            <p className="text-rose-600">GAS: {configs?.gas || 0}</p>
          </div>
          <div className="flex items-center lg:order-2 lg:hidden">
            <button
              onClick={() => setMobileMenuClosed(!mobileMenuClosed)}
              data-collapse-toggle="mobile-menu-2"
              type="button"
              className="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
              aria-controls="mobile-menu-2"
              aria-expanded="false"
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <svg
                className="hidden w-6 h-6"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </div>
          <div
            className={`items-center justify-between w-full lg:flex lg:w-auto lg:order-1 ${
              mobileMenuClosed ? "hidden" : ""
            }`}
            id="mobile-menu-2"
          >
            <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
              {routes?.map((route, index) => (
                <Fragment key={index}>
                  <li>
                    <Link to={route.to}>
                      <span
                        className={`block py-2 pl-3 pr-4 hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 ${
                          location.pathname === route.to
                            ? " text-primary-700"
                            : "text-gray-700"
                        }`}
                        aria-current="page"
                      >
                        {route.name}
                      </span>
                    </Link>
                  </li>
                </Fragment>
              ))}
              <li>
                <button
                  className="block py-2 pl-3 pr-4 text-gray-700 hover:bg-gray-50 lg:hover:bg-transparent lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 lg:w-fit w-full text-left"
                  onClick={logOut}
                >
                  Log Out
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default memo(TopNavigation);
