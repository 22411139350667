import { Fragment, memo, useCallback, useState } from "react";
import Title from "../molecules/Title";
import { Dialog } from "@headlessui/react";
import Input from "../atoms/Input";
import {
  AdjustmentsHorizontalIcon,
  CalendarDaysIcon,
} from "@heroicons/react/24/outline";

const PageTitle = ({ title, filters, actions, setFilter }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState();

  const handleOnChange = useCallback(({ target: { name, value } }) => {
    setOptions((prev) => ({ ...prev, [name]: value }));
  }, []);

  const onSubmit = () => {
    setFilter((prev) => ({ ...prev, ...options }));
  };

  return (
    <div className="flex justify-between items-center">
      <Title title={title} />
      <div className="flex items-center gap-2">
        {actions?.map((action, index) => (
          <Fragment key={index}>{action}</Fragment>
        ))}
        {actions ? <div className="border-r border-slate-300 h-fit" /> : null}
        <button
          className="py-1 px-5 hover:cursor-pointer hover:text-primary-500 text-black flex gap-1 items-center"
          onClick={() => setIsOpen(true)}
        >
          <AdjustmentsHorizontalIcon className="h-4 w-4 cursor-pointer" />
          Select Filtetr
        </button>
        <button
          onClick={() =>
            setFilter((prev) => ({ ...prev, created_at: new Date() }))
          }
          className="border hover:cursor-pointer py-1 px-5 rounded shadow-sm shadow-primary-500 border-primary-500 hover:bg-primary-500 hover:text-white text-black flex gap-1 items-center"
        >
          <CalendarDaysIcon className="h-4 w-4 cursor-pointer" />
          Today
        </button>
      </div>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className="relative z-50"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        {/* Full-screen scrollable container */}
        <div className="fixed inset-0 overflow-y-auto">
          {/* Container to center the panel */}
          <div className="flex min-h-full items-center justify-center">
            {/* The actual dialog panel  */}
            <Dialog.Panel className="ml-auto max-w-lg sm:w-1/2 w-full bg-white h-[100vh] p-5">
              <Dialog.Title>
                <div className="flex justify-between items-center w-full">
                  <h2 className="text-xl font-semibold">Choose your filter</h2>
                  <div
                    className="shadow-sm shadow-primary-500 rounded-tr-lg rounded-tl-sm rounded-bl-lg rounded-br-sm hover:bg-primary-600 px-2 py-1 text-gray-500 hover:text-white cursor-pointer"
                    onClick={() => setIsOpen(false)}
                  >
                    X
                  </div>
                </div>
              </Dialog.Title>
              <div className="flex flex-col justify-between h-full pt-5 pb-10">
                <div className="overflow-y-auto">
                  {filters?.map(({ name, type, placeholder, label }, index) => (
                    <Input
                      key={index}
                      name={name}
                      type={type}
                      placeholder={placeholder}
                      labelText={label}
                      onChange={handleOnChange}
                      className="my-3 w-full rounded"
                    />
                  ))}
                </div>
                <button
                  onClick={onSubmit}
                  className="border hover:cursor-pointer py-2 px-5 rounded-md shadow-sm shadow-primary-500 border-primary-500 hover:bg-primary-500 hover:text-white text-black"
                >
                  Apply Filter
                </button>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default memo(PageTitle);
