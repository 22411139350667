import Client from "./clients";
import Types from "../utils/constants/repositoryTypes";
import MainRepository from "./MainRepository";
const resource = Types.BANK;

export default {
    ...MainRepository(resource),
    post: (payload) => {
        return Client.post(`${resource}/`, payload);
    },
}