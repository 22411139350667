import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLazyQuery, useMutation } from "@apollo/client";

import LoginFormTemplate from "components/organisms/Forms/LoginFormTemplate";

import { LOGIN_MUTATION } from "utils/gql/mutations";
import { GET_SITE_CONFIG_QUERY } from "utils/gql/queries/site-config.query";

import { setAuth } from "store/actions/auth";
import { setSiteConfigs } from "store/actions/siteConfig";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login] = useMutation(LOGIN_MUTATION);
  const [getSite] = useLazyQuery(GET_SITE_CONFIG_QUERY);

  const onSubmit = useCallback(async (credentials) => {
    const auth = await login({ variables: { ...credentials } });
    const site = await getSite({
      variables: { id: "" },
    });
    dispatch(setAuth(auth?.data?.signIn));
    dispatch(setSiteConfigs(site?.data?.siteConfig));
    localStorage.setItem("user", "true");
    navigate("", { replace: true });
  }, []);

  return (
    <div className="flex justify-center flex-col w-full max-w-[500px] shrink px-8">
      <div className="mb-5">
        <h1 className="text-4xl font-bold">Login</h1>
        <p className="text-xs">Enter your account details</p>
      </div>
      <LoginFormTemplate onSubmit={onSubmit} />
    </div>
  );
};

export default Login;
