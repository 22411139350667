import { gql } from "@apollo/client";
export const GET_USERS_QUERY = gql`
  query Users($filter: TransactionFilter!) {
    users(filter: $filter) {
      totalPages
      currentPage
      perPage
      total
      data {
        _id
        name
        email
        username
        role
        status
        createdAt
        updatedAt
      }
    }
  }
`;
