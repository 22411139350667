import { gql } from "@apollo/client";

export const GET_DEPOSITS_QUERY = gql`
  query Deposits($filter: TransactionFilter!) {
    deposits(filter: $filter) {
      totalPages
      currentPage
      perPage
      total
      data {
        _id
        playerId
        requestedAt
        category
        toAccount
        rib
        fullName
        amount
        status
        actionAt
        action
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_SUCCESS_PAID_DEPOSITS_QUERY = gql`
  query PaidDeposits($filter: TransactionFilter!) {
    statisticDeposits(filter: $filter) {
      totalPages
      currentPage
      perPage
      total
      data {
        amount
        count
        action
        date
      }
    }
  }
`;
