import useAuth from "./useAuth";

const usePermission = () => {
  const { auth } = useAuth();
  const $can = (roles = []) => auth && roles.includes(auth.role);

  return { $can };
};

export default usePermission;
