import { gql } from "@apollo/client";

export const GET_BANKS_QUERY = gql`
  query Banks($filter: TransactionFilter!) {
    banks(filter: $filter) {
      _id
      name
      logo
    }
  }
`;
export const GET_ACCOUNTS_QUERY = gql`
  query Accounts($filter: TransactionFilter!) {
    accounts(filter: $filter) {
      totalPages
      currentPage
      perPage
      total
      data {
        _id
        accountNumber
        status
        totalDeposit
        totalWithdrawal
        user
        owner
        actionedAt
        bank {
          _id
          name
          logo
        }
        creator {
          _id
          name
          username
        }
      }
    }
  }
`;

export const GET_ACCOUNTS_FOR_SERRINGS_FORM_QUERY = gql`
  query Accounts($filter: TransactionFilter!) {
    accounts(filter: $filter) {
      _id
      accountNumber
      bank {
        name
        _id
      }
    }
  }
`;
