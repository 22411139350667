import { useEffect, useRef, useState } from "react";

const useDebounce = (value, delay = 500) => {
    const [debounceValue, setDebounceValue] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const timeoutRef = useRef();

    useEffect(() => {
        setIsLoading(true);
        timeoutRef.current = setTimeout(() => {
            setDebounceValue(value);
            setIsLoading(false);
        }, delay);

        return () => clearTimeout(timeoutRef.current);
    }, [value, delay])

    return{ debounceValue, setDebounceValue, isLoading};
}

export default useDebounce;
