import { useState } from "react";
import classNames from "classnames";
import styles from "styles/components/Input.module.scss";

const Input = ({
  id,
  name,
  type,
  value,
  className,
  onChange,
  errorText,
  labelText,
  placeholder,
  disabled,
  onFocus,
  onBlur,
  fileRef,
}) => {
  const [isFocused, setIsFocused] = useState(!!value || false);

  return (
    <div
      className={classNames([
        styles.input_group,
        isFocused ? "focused" : "",
        disabled ? "!cursor-not-allowed" : "",
      ])}
    >
      <label disabled={disabled} htmlFor={id}>
        {labelText && <span>{labelText}</span>}
      </label>
      <input
        value={value || ""}
        id={id}
        name={name}
        type={type}
        onChange={onChange}
        disabled={disabled}
        onBlur={(e) => {
          setIsFocused(!!e.target.value);
          onBlur(e.target.value);
        }}
        onFocus={() => {
          setIsFocused(true);
          onFocus && onFocus();
        }}
        className={classNames([className])}
        ref={fileRef}
      />
      {errorText && <p>{errorText}</p>}
    </div>
  );
};

export default Input;
