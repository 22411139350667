import React, { Children, cloneElement, isValidElement } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";

const Form = ({
  onSubmit,
  formSchema,
  defaultValues,
  buttonContent,
  className,
  children,
}) => {
  // const {
  //   handleSubmit,
  //   register,
  //   control,
  //   reset,
  //   getValues,
  //   setValue,
  //   formState,
  // } = useForm({
  //   defaultValues,
  //   mode: "onChange",
  //   resolver: yupResolver(formSchema),
  // });

  // const childrenWithProps = Children.map(children, (child) => {
  //   if (isValidElement(child))
  //     return cloneElement(child, {
  //       control,
  //       setValue,
  //       register,
  //       getValues,
  //       formState,
  //     });

  //   return child;
  // });

  // const submit = (data) => {
  //   onSubmit(data);
  //   reset();
  // };

  // const error = (e) => {
  //   console.log(e);
  // };

  return (
    // <form onSubmit={handleSubmit(submit, error)}>
    <form onSubmit={onSubmit}>
      <div className={classNames([className])}>{children}</div>
      {buttonContent && (
        <button
          type="submit"
          className="inline-flex w-full mt-1 justify-center rounded-md border border-transparent bg-primary-100 px-4 py-2 text-sm font-medium text-primary-900 hover:bg-primary-200 border-primary-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2"
        >
          {buttonContent}
        </button>
      )}
    </form>
  );
};

export default Form;
