import classNames from "classnames";
import styles from "../../styles/components/GridTable.module.scss";
import { STATUS } from "../../utils/constants/statuses";
import dayjs from "utils/plugins/dayjs";
import InfiniteScroll from "react-infinite-scroller";

const TBody = ({
  data,
  dataKeys,
  actions,
  dateFormated = [],
  loadMore,
  hasMoreItems,
}) => {
  return (
    <div className={styles.body}>
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={hasMoreItems}
        loader={<div className="loader"> Loading... </div>}
        useWindow={false}
        className={styles.content}
      >
        {data.map((row, index) => (
          <div key={index}>
            {dataKeys.map((item, i) => {
              let [first, second] = item.includes(".")
                ? item.split(".").map((item) => item.split(" "))
                : [item];
              return (
                <div
                  key={i}
                  className={`${
                    item === "status"
                      ? row[item]
                        ? "text-green-500"
                        : "text-red-500"
                      : ""
                  }`}
                >
                  {dateFormated.includes(first) && row[first]
                    ? dayjs(row[first]).format("llll")
                    : item === "status"
                    ? STATUS[+row[item]]
                    : second
                    ? row[first][second]
                    : Array.isArray(row[first])
                    ? row[first].join("; ")
                    : row[first]}
                </div>
              );
            })}

            <div className="flex items-center gap-2 justify-end">
              {actions?.map((action, index) => (
                <button
                  key={index}
                  className="w-4 h-4"
                  onClick={() =>
                    action.action({ id: row._id, status: row.status })
                  }
                >
                  {action.alternativeComponent && row.status ? (
                    <action.alternativeComponent
                      className={classNames([
                        `${
                          row.status ? "text-green-500" : "text-red-500"
                        } cursor-pointer`,
                        action.className,
                      ])}
                    />
                  ) : (
                    <action.component
                      className={classNames([
                        `${
                          row.status ? "text-green-500" : "text-red-500"
                        } cursor-pointer`,
                        action.className,
                      ])}
                    />
                  )}
                </button>
              ))}
            </div>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default TBody;
