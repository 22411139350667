import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Form from "../FormV1";
import InputGroup from "components/molecules/InputGroup";
import { addSettingsSchema } from "utils/validations/settings-validations";
import { ADD_SETTINGS_INPUTS } from "utils/constants/settings";

const AddSettingsFormTemplate = ({
  onSubmit,
  buttonContent,
  defaultValues,
  options,
}) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(addSettingsSchema),
    defaultValues,
  });

  return (
    <Form buttonContent={buttonContent} onSubmit={handleSubmit(onSubmit)}>
      {ADD_SETTINGS_INPUTS.map((input) => (
        <InputGroup
          control={control}
          id={input.id}
          type={input.type}
          key={input.name}
          name={input.name}
          label={input.label || ""}
          placeholder={input.placeholder || ""}
          component={input.component}
          error={errors[input.name]}
          {...(input.options && input.type === "select"
            ? {
                options: options[input.name] || [],
              }
            : {})}
          {...(input.customOption && input.type === "select"
            ? { CustomOption: input.customOption }
            : {})}
          {...(input.type === "select" ? { isMulti: input.isMulti } : {})}
        />
      ))}
    </Form>
  );
};

export default AddSettingsFormTemplate;
