import { yupResolver } from "@hookform/resolvers/yup";
import Form from "../FormV1";
import InputGroup from "components/molecules/InputGroup";
import { useForm } from "react-hook-form";
import { loginSchema } from "utils/validations/auth-validations";
import { USER_LOGIN_INPUTS } from "utils/constants/user";

const LoginFormTemplate = ({ onSubmit }) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(loginSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  return (
    <Form buttonContent="Login" onSubmit={handleSubmit(onSubmit)}>
      {USER_LOGIN_INPUTS.map((input) => (
        <InputGroup
          control={control}
          id={input.id}
          type={input.type}
          key={input.name}
          name={input.name}
          label={input.label || ""}
          placeholder={input.placeholder || ""}
          component={input.component}
          error={errors[input.name]}
        />
      ))}
    </Form>
  );
};

export default LoginFormTemplate;
