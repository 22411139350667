import RepositoryFactory from "../repositories/RepositoryFactory";
import Types from "../utils/constants/repositoryTypes";

const api = RepositoryFactory.get(Types.TRANSACTION);

const TransactionService = (() => {
    let instance = null;
     const createInstance = () => {
        return {
            get: async (payload) => {
                return api.get(payload);
            },
            block: async (id, payload) => {
                return api.update(id, payload);
            },
            resend: async (id) => {
                return api.resend(id);
            },
        }
     }

     return {
        getInstance: () => {
            if(!instance) {
                instance = createInstance();
            }

            return instance;
        }
     }
})();

export default TransactionService.getInstance();
