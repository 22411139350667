import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  settings: [],
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: (create) => ({
    setSettings: create.preparedReducer(
      (payload) => payload,
      (state, action) => {
        state.settings = action.payload;
      }
    ),
  }),
  selectors: {
    getSettings: (sliceState) => sliceState.settings,
  },
});

export const { setSettings } = settingsSlice.actions;
// export const { getSettings } = settingsSlice.selectors;
export default settingsSlice.reducer;
