import { Fragment } from "react";
import { MODAL_TYPES } from "../utils/constants/modals";
import GridTable from "../components/organisms/GridTable";
import PageTitle from "../components/organisms/PageTitle";
import { useGlobalModalContext } from "../components/organisms/GlobalModal";
import {
  USER_ROLES,
  USERS_FILTER_INPUTS,
  USERS_TABLE_HEADERS,
} from "../utils/constants/user";
import {
  LockClosedIcon,
  LockOpenIcon,
  PaperAirplaneIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import UserService from "../services/UserService";
import useFilter from "../utils/hooks/useFilter";
import useRequest from "../utils/hooks/useRequest";
import { GET_USERS_QUERY } from "utils/gql/queries/user.query";
import { useMutation, useQuery } from "@apollo/client";
import {
  DELETE_USER_MUTATION,
  UPDATE_USER_MUTATION,
} from "utils/gql/mutations/user.mutation";
import usePermission from "utils/hooks/usePermission";

const Users = () => {
  const { $can } = usePermission();
  const { showModal } = useGlobalModalContext();
  const {
    filterOptions,
    setFilterOptions,
    isLoading: isFilterLoading,
  } = useFilter();

  const { loading, error, data, fetchMore } = useQuery(GET_USERS_QUERY, {
    variables: { filter: { ...filterOptions, page: 1 } },
  });

  const loadMore = () => {
    if (data?.users?.currentPage >= data?.users?.totalPages) return;
    fetchMore({
      variables: {
        filter: {
          ...filterOptions,
          page: data?.users?.currentPage + 1,
        },
      },
    });
  };

  const [remove, { error: _error, rest }] = useMutation(DELETE_USER_MUTATION);
  const [update, { error: _updateError, rest: updateRest }] =
    useMutation(UPDATE_USER_MUTATION);

  const _showModal = () => {
    showModal(MODAL_TYPES.ADD_USER_MODAL, {
      title: "Add User",
      confirmBtn: "Save",
    });
  };

  const _showDeleteModal = ({ id }) => {
    showModal(MODAL_TYPES.DELETE_MODAL, {
      id,
      remove,
      refetchQueries: [GET_USERS_QUERY],
    });
  };

  const userLockUnlock = async ({ id, status }) => {
    update({ variables: { id, status: +!status } });
  };

  // const sendUserPassword = async (id) => {
  //   await UserService.sendPassword(id);
  // };

  return (
    <Fragment>
      <PageTitle
        title="Users"
        filters={USERS_FILTER_INPUTS}
        setFilter={setFilterOptions}
        actions={[
          $can(USER_ROLES.ALL) && (
            <button
              className="border cursor-pointer py-1 px-5 rounded shadow-lg hover:shadow-gray-500 transition-shadow border-primary-500 bg-primary-500 text-white"
              onClick={_showModal}
            >
              Add User
            </button>
          ),
        ]}
      />
      <GridTable
        loadMore={loadMore}
        hasMoreItems={data?.users?.currentPage < data?.users?.totalPages}
        headers={USERS_TABLE_HEADERS}
        dataKeys={[
          "_id",
          "email",
          "name",
          "status",
          "role",
          "username",
          "createdAt",
          "updatedAt",
        ]}
        dateFormated={["createdAt", "updatedAt"]}
        data={
          data?.users?.data ||
          [
            // [
            //   "email@email.com",
            //   "Admin",
            //   <LockClosedIcon
            //     onClick={() => userLockUnlock(1)}
            //     className="h-4 w-4 text-gray-500 cursor-pointer"
            //   />,
            //   <PaperAirplaneIcon
            //     onClick={() => sendUserPassword(1)}
            //     className="h-4 w-4 text-gray-500 cursor-pointer"
            //   />,
            //   <TrashIcon
            //     onClick={() => _showDeleteModal(1)}
            //     className="h-4 w-4 text-red-500 hover:text-red-800 cursor-pointer"
            //   />,
            // ],
          ]
        }
        actions={
          $can(USER_ROLES.ALL)
            ? [
                {
                  action: userLockUnlock,
                  component: LockClosedIcon,
                  alternativeComponent: LockOpenIcon,
                  className: "h-4 w-4 !text-gray-500 cursor-pointer",
                },
                // {
                //   action: sendUserPassword,
                //   component: PaperAirplaneIcon,
                //   className: "h-4 w-4 text-gray-500 cursor-pointer",
                // },
                {
                  action: _showDeleteModal,
                  component: TrashIcon,
                  className:
                    "h-4 w-4 !text-red-500 hover:text-red-800 cursor-pointer",
                },
              ]
            : []
        }
      />
    </Fragment>
  );
};

export default Users;
