import Client from "./clients";
import Types from "../utils/constants/repositoryTypes";
const resource = Types.AUTH;

export default {
  login: (credentials) => {
    return Client.post(`${resource}/login`, credentials);
  },

  invite: (credentials) => {
    return Client.post(`${resource}/register`, credentials);
  },

  logout: () => {
    return Client.post(`${resource}/logout`, {});
  },
};
