import { Fragment } from "react";
import PageTitle from "components/organisms/PageTitle";
import GridTable from "components/organisms/GridTable";
import { SETTINGS_HEADERS } from "utils/constants/settings";
import { useGlobalModalContext } from "components/organisms/GlobalModal";
import { MODAL_TYPES } from "utils/constants/modals";
import useFilter from "utils/hooks/useFilter";
import { useMutation, useQuery } from "@apollo/client";
import usePermission from "utils/hooks/usePermission";
import { GET_SETTINGS_QUERY } from "utils/gql/queries/settings.query";
import { PowerIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { UPDATE_SETTINGS_MUTATION } from "utils/gql/mutations/settings.mutation";
import { USER_ROLES } from "utils/constants/user";

const Settings = () => {
  const { $can } = usePermission();
  const { showModal } = useGlobalModalContext();
  const {
    filterOptions,
    setFilterOptions,
    isLoading: isFilterLoading,
  } = useFilter();
  const { loading, error, data, fetchMore } = useQuery(GET_SETTINGS_QUERY, {
    variables: { filter: { ...filterOptions, page: 1 } },
  });

  const loadMore = () => {
    if (data?.configs?.currentPage >= data?.configs?.totalPages) return;
    fetchMore({
      variables: {
        filter: {
          ...filterOptions,
          page: data?.configs?.currentPage + 1,
        },
      },
    });
  };

  const [update, { error: _error, rest }] = useMutation(
    UPDATE_SETTINGS_MUTATION
  );
  const _showModal = () => {
    showModal(MODAL_TYPES.ADD_SETTINGS_MODAL, {
      title: "Add Config",
      confirmBtn: "Save",
    });
  };

  const _showEditModal = ({ id }) => {
    showModal(MODAL_TYPES.EDIT_SETTINGS_MODAL, {
      title: "Update Config",
      confirmBtn: "Update",
      id,
    });
  };
  const actionHandler = ({ id, status }) => {
    update({ variables: { id, status: +!status } });
  };
  return (
    <Fragment>
      <PageTitle
        title="Settings"
        setFilter={setFilterOptions}
        actions={[
          $can([USER_ROLES.ALL, USER_ROLES.CONFIG]) && (
            <button
              className="border cursor-pointer py-1 px-5 rounded shadow-lg hover:shadow-gray-500 transition-shadow border-primary-500 bg-primary-500 text-white"
              onClick={_showModal}
            >
              Add Config
            </button>
          ),
        ]}
      />
      <GridTable
        loadMore={loadMore}
        hasMoreItems={data?.configs?.currentPage < data?.configs?.totalPages}
        headers={SETTINGS_HEADERS}
        dataKeys={["_id", "bankId", "status", "actionedAt", "user"]}
        dateFormated={["actionedAt"]}
        actions={
          $can([USER_ROLES.ALL, USER_ROLES.CONFIG])
            ? [
                { action: actionHandler, component: PowerIcon },
                {
                  action: _showEditModal,
                  component: PencilSquareIcon,
                  className: "!text-gray-500 cursor-pointer",
                },
              ]
            : []
        }
        data={data?.configs?.data || []}
      />
    </Fragment>
  );
};

export default Settings;
