import { Outlet } from 'react-router-dom';
import TopNavigation from '../components/organisms/TopNavigation';

const UserLayout = () => (
  <>
    <TopNavigation />
    <section className='pt-20 max-w-screen-2xl px-11 pb-8 mx-auto'>
      <Outlet />
    </section>
  </>
);

export default UserLayout;