import { gql } from "@apollo/client";

export const GET_SITE_CONFIG_QUERY = gql`
  query SiteConfig($id: String!) {
    siteConfig(id: $id) {
      _id
      name
      callbackUrl
      gas
      requestPrice
    }
  }
`;
