import * as Yup from "yup";

export const addUserSchema = Yup.object()
  .shape({
    name: Yup.string().trim().required("Name is required"),
    username: Yup.string().trim().required("Username is required"),
    email: Yup.string()
      .trim()
      .required("Email address is required")
      .email("Invalid email address"),
    permission: Yup.mixed().required("Permission field is required"),
  })
  .required();
