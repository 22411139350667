import { useRoutes } from "react-router-dom";
import "./App.css";
import routes from "./router";
import useAuth from "./utils/hooks/useAuth";

const App = () => {
  const { isLoggedIn } = useAuth();

  const routing = useRoutes(routes(isLoggedIn));

  return <>{routing}</>;
};

export default App;
