export const ADD_BANK_INPUTS = [
  {
    title: "Select existing bank",
    id: "bank",
    name: "bank",
    type: "select",
    label: "Bank",
    placeholder: "Bank",
    component: import("components/atoms/select"),
    disableDependsOn: "bank_name",
    options: [],
  },
  {
    title: "or Add new bank",
    id: "bank_name",
    name: "bank_name",
    type: "text",
    label: "Bank Name",
    placeholder: "Bank Name",
    component: import("components/atoms/Input"),
    disableDependsOn: "bank_id",
  },
  {
    id: "logo",
    name: "logo",
    type: "file",
    label: "",
    placeholder: "",
    component: import("components/atoms/Input"),
    disableDependsOn: "bank_id",
    classes: "pb-4 mb-4 border-b border-gray-300",
    onChange: (e) => e.target.files[0],
  },
  {
    id: "bank_acc_num",
    name: "bank_acc_num",
    type: "text",
    label: "Bank Acc. Num.",
    placeholder: "Bank Acc. Num.",
    component: import("components/atoms/Input"),
  },
  {
    id: "bank_acc_holder",
    name: "bank_acc_holder",
    type: "text",
    label: "Bank Acc. Holder",
    placeholder: "Bank Acc. Holder",
    component: import("components/atoms/Input"),
  },
  // {id: 'username', name: 'username', type: 'text', label: 'Username', placeholder: 'Username'},
  // {id: 'password', name: 'password', type: 'password', label: 'Password', placeholder: 'Password'},
  // {id: 'hourly_limit', name: 'hourly_limit', type: 'number', label: 'Hourly Limit', placeholder: 'Hourly Limit'},
  // {id: 'daily_limit', name: 'daily_limit', type: 'number', label: 'Daily Limit', placeholder: 'Daily Limit'},
  // {id: 'weekly_limit', name: 'weekly_limit', type: 'number', label: 'Weekly Limit', placeholder: 'Weekly Limit'},
  // {id: 'monthly_limit', name: 'monthly_limit', type: 'number', label: 'Monthly Limit', placeholder: 'Monthly Limit'},
];

export const BANK_SETTING_HEADERS = [
  "Bank ID",
  "Bank Name",
  "Bank Acc. Num.",
  "Bank Acc. Holder",
  "Status",
  "Total D",
  "Total W",
  "User",
  "Action",
];

//   "Default Hourly Limit",
//   "Default Daily Limit",
//   "Default Weekly Limit",
//   "Default Monthly Limit",
//   "Bank Password",
//   "Bank Captcha",

export const BANK_SETTINGS_FILTER_INPUTS = [
  {
    name: "bank_name",
    type: "text",
    placeholder: "Bank Name",
    label: "Bank Name",
    component: import("components/atoms/Input"),
  },
  {
    name: "account_num",
    type: "text",
    placeholder: "Account Number",
    label: "Account Number",
    component: import("components/atoms/Input"),
  },
  {
    name: "account_holder",
    type: "text",
    placeholder: "Account Holder",
    label: "Account Holder",
    component: import("components/atoms/Input"),
  },
];
