export const WITHDRAW_TABLE_HEADERS = [
  "Player ID",
  "Bank Name Sent",
  "Account Number Sent",
  "First Name",
  "Last Name",
  "Amount",
  "Date/Time Request",
  "Check",
];

export const SELECT_BANK_TABLE_HEADERS = [
  "Bank ID",
  "Bank Name",
  "Bank Acc. Numb.",
  "Check",
];

export const WITHDRAW_FILTER_INPUTS = [
  {
    name: "player_id",
    type: "text",
    placeholder: "Player ID",
    label: "Player ID",
    component: import("components/atoms/Input"),
  },
  {
    name: "bank_name_sent",
    type: "text",
    placeholder: "Bank Name Sent",
    label: "Bank Name Sent",
    component: import("components/atoms/Input"),
  },
  {
    name: "account_num_sent",
    type: "text",
    placeholder: "Account Number Sent",
    label: "Account Number Sent",
    component: import("components/atoms/Input"),
  },
  {
    name: "first_name",
    type: "text",
    placeholder: "First Name",
    label: "First Name",
    component: import("components/atoms/Input"),
  },
  {
    name: "last_name",
    type: "text",
    placeholder: "Last Name",
    label: "Last Name",
    component: import("components/atoms/Input"),
  },
];
