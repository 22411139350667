import { gql } from "@apollo/client";

export const GET_SETTINGS_QUERY = gql`
  query Configs($filter: TransactionFilter!) {
    configs(filter: $filter) {
      totalPages
      currentPage
      perPage
      total
      data {
        _id
        bankId
        status
        user
        actionedAt
      }
    }
  }
`;

export const GET_SETTING_QUERY = gql`
  query Config($id: String!) {
    config(id: $id) {
      _id
      bankId
      playerId
      user
      type
      time
    }
  }
`;
