export const DASHBOARD_TABLE_HEADERS = [
  "",
  "Count",
  "Amount",
  "Success",
  "Date",
  "",
];

export const DASHBOARD_FILTER_INPUTS = [
  {
    name: "transaction_id",
    type: "text",
    placeholder: "Transaction ID",
    component: import("components/atoms/Input"),
  },
];
