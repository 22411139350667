import { gql } from "@apollo/client";
export const AUTH_USER_QUERY = gql`
  query User {
    currentUser {
      role
      access_token
      refresh_token
    }
  }
`;
