import imageCompression from "browser-image-compression";

export const $can = (roles = []) => {
  const user = localStorage.getItem("user");
  const _user = user ? JSON.parse(user) : null;
  console.log(roles, _user.roles);
  return _user && roles.includes(_user.roles);
};

export const compressor = async (
  file,
  options = {
    maxSizeMB: 0.5,
    maxWidthOrHeight: 192,
    useWebWorker: true,
  }
) => {
  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (err) {
    console.log(err);
    return file;
  }
};
