import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { mergeOptionsForPaginations } from "utils/gql/options";

export const authLink = setContext((_, { headers }) => {
  const user = localStorage.getItem("user") || null;
  let token = null;
  if (user) {
    token = JSON.parse(user);
  }

  const hostname = document.location.hostname;

  return {
    headers: {
      ...headers,
      "X-Site": hostname,
      // authorization: token ? `Bearer ${token.access_token}` : "",
    },
  };
});

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
  credentials: "include",
});

const uploadLink = createUploadLink({
  uri: `${process.env.REACT_APP_API_BASE_URL}/graphql`,
  credentials: "include",
  headers: {
    "keep-alive": "true",
    "Apollo-Require-Preflight": "true",
  },
});

export const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          ...mergeOptionsForPaginations,
        },
      },
    },
  }),
  // link: authLink.concat(uploadLink).concat(httpLink),
  link: from([authLink, uploadLink]),
});
