import * as Yup from "yup";

export const addBankSchema = Yup.object()
  .shape(
    {
      bank_name: Yup.string().when("bank", {
        is: undefined,
        then: Yup.string().trim().required("Bank name field is required"),
        otherwise: Yup.string().optional().notRequired(),
      }),
      logo: Yup.string().when("bank", {
        is: undefined,
        then: Yup.string().trim().required("Logo field is required"),
        otherwise: Yup.string().optional().notRequired(),
      }),
      bank: Yup.mixed().when("bank_name", {
        is: undefined,
        then: Yup.mixed().required("Please choose a bank"),
        otherwise: Yup.mixed().optional().notRequired(),
      }),
      bank_acc_num: Yup.string().trim().required("Bank name field is required"),
      bank_acc_holder: Yup.string()
        .trim()
        .required("Bank account holder field is required"),
    },
    ["bank", "bank_name"]
  )
  .required();
