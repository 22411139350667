import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  transactions: [],
};

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: (create) => ({
    setTransactions: create.preparedReducer(
      (payload) => payload,
      (state, action) => {
        state.transactions = action.payload;
      }
    ),
  }),
  selectors: {
    getTransactions: (sliceState) => sliceState.transactions,
  },
});

export const { setTransactions } = transactionSlice.actions;
// export const { getTransactions } = transactionSlice.selectors;
export default transactionSlice.reducer;
