export const ACTION_STATUS = {
  PENDING: "pending",
  PAID: "paid",
  DECLINED: "declined",
};

export const DEPOSIT_TABLE_HEADERS = [
  "Player ID",
  "Requested At",
  "Category",
  "Account Number Sent",
  "RIB",
  "Full Name",
  "Amount",
  "Action At",
  "Created At",
  "Updated At",
  "Status",
  "Action",
];

export const SELECT_BANK_TABLE_HEADERS = [
  "Bank ID",
  "Bank Name",
  "Bank Acc. Numb.",
  "Check",
];

export const DEPOSIT_FILTER_INPUTS = [
  {
    name: "player_id",
    type: "text",
    placeholder: "Player ID",
    label: "Player ID",
    component: import("components/atoms/Input"),
  },
  {
    name: "bank_name_sent",
    type: "text",
    placeholder: "Bank Name Sent",
    label: "Bank Name Sent",
    component: import("components/atoms/Input"),
  },
  {
    name: "account_num_sent",
    type: "text",
    placeholder: "Account Number Sent",
    label: "Account Number Sent",
    component: import("components/atoms/Input"),
  },
  {
    name: "first_name",
    type: "text",
    placeholder: "First Name",
    label: "First Name",
    component: import("components/atoms/Input"),
  },
  {
    name: "last_name",
    type: "text",
    placeholder: "Last Name",
    label: "Last Name",
    component: import("components/atoms/Input"),
  },
];
