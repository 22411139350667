import TBody from "../molecules/TBody";
import THeader from "../molecules/THeader";

import styles from "../../styles/components/GridTable.module.scss";

const GridTable = ({
  headers,
  dataKeys,
  actions,
  data,
  dateFormated = [],
  loadMore,
  hasMoreItems,
}) => {
  return (
    <div className={styles.main}>
      <THeader headers={headers} />
      <TBody
        data={data}
        dataKeys={dataKeys}
        actions={actions}
        dateFormated={dateFormated}
        loadMore={loadMore}
        hasMoreItems={hasMoreItems}
      />
    </div>
  );
};

export default GridTable;
