import * as Yup from 'yup';

export const loginSchema = Yup.object()
  .shape({
    username: Yup.string().trim().required('Username is required!'),
    password: Yup.string()
      .trim()
      .required('Password field is required!')
      .min(3, 'Password is required. Min. 3')
      .max(20, 'Password is too long. Max. 20'),
  })
  .required();