import { Fragment, useState } from "react";
import { useGlobalModalContext } from "../components/organisms/GlobalModal";
import GridTable from "../components/organisms/GridTable";
import PageTitle from "../components/organisms/PageTitle";
// import BankService from "../services/BankService";
import {
  BANK_SETTINGS_FILTER_INPUTS,
  BANK_SETTING_HEADERS,
} from "../utils/constants/bank-settings";
import { MODAL_TYPES } from "../utils/constants/modals";
import useFilter from "../utils/hooks/useFilter";
// import useRequest from "../utils/hooks/useRequest";
import { useQuery, useMutation } from "@apollo/client";
import usePermission from "utils/hooks/usePermission";
import { GET_ACCOUNTS_QUERY } from "../utils/gql/queries";
import { PowerIcon } from "@heroicons/react/24/outline";
import { UPDATE_ACCOUNT_MUTATION } from "../utils/gql/mutations/bank.mutation";
import { useGetAccountsQuery } from "store/actions/bank";
import { USER_ROLES } from "utils/constants/user";

const BankSettings = () => {
  const { $can } = usePermission();
  const { showModal } = useGlobalModalContext();
  const [banks, setBanks] = useState([]);
  const {
    filterOptions,
    setFilterOptions,
    isLoading: isFilterLoading,
  } = useFilter();
  const { loading, error, data, fetchMore } = useQuery(GET_ACCOUNTS_QUERY, {
    variables: { filter: { ...filterOptions, page: 1 } },
  });
  const loadMore = () => {
    if (data?.accounts?.currentPage >= data?.accounts?.totalPages) return;
    fetchMore({
      variables: {
        filter: {
          ...filterOptions,
          page: data?.accounts?.currentPage + 1,
        },
      },
    });
  };

  const [update, { error: _error, rest }] = useMutation(
    UPDATE_ACCOUNT_MUTATION
  );

  const _showModal = () => {
    showModal(MODAL_TYPES.ADD_BANK_MODAL, {
      title: "Add Bank",
      confirmBtn: "Save",
    });
  };

  const actionHandler = ({ id, status }) => {
    update({ variables: { id, status: +!status } });
  };

  return (
    <Fragment>
      <PageTitle
        title="Bank Settings"
        filters={BANK_SETTINGS_FILTER_INPUTS}
        setFilter={setFilterOptions}
        actions={[
          $can([USER_ROLES.ALL, USER_ROLES.BANK]) && (
            <button
              className="border cursor-pointer py-1 px-5 rounded shadow-lg hover:shadow-gray-500 transition-shadow border-primary-500 bg-primary-500 text-white"
              onClick={_showModal}
            >
              Add Bank
            </button>
          ),
        ]}
      />
      {!loading && (
        <GridTable
          loadMore={loadMore}
          hasMoreItems={
            data?.deposits?.currentPage < data?.deposits?.totalPages
          }
          headers={BANK_SETTING_HEADERS}
          dataKeys={[
            "_id",
            "bank.name",
            "accountNumber",
            "owner",
            "status",
            "totalDeposit",
            "totalWithdrawal",
            "actionedAt",
          ]}
          actions={
            $can([USER_ROLES.ALL, USER_ROLES.BANK])
              ? [{ action: actionHandler, component: PowerIcon }]
              : []
          }
          data={data?.accounts?.data || []}
        />
      )}
    </Fragment>
  );
};

export default BankSettings;
