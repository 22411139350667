import { gql } from "@apollo/client";
export const CREATE_BANK_MUTATION = gql`
  mutation CreateBank(
    $bank: String!
    $name: String
    $logo: Upload!
    $owner: String!
    $accountNumber: String!
  ) {
    createBank(
      bankCreateInput: {
        bank: $bank
        name: $name
        logo: $logo
        owner: $owner
        accountNumber: $accountNumber
      }
    ) {
      _id
      accountNumber
      status
      totalDeposit
      totalWithdrawal
      user
      owner
      actionedAt
      bank {
        _id
        name
        logo
      }
      creator {
        _id
        name
        username
      }
    }
  }
`;

export const UPDATE_BANK_MUTATION = gql`
  mutation UpdateBank($id: String!, $status: Int!) {
    updateBank(bankUpdateInput: { id: $id, status: $status }) {
      _id
      name
      logo
      accountNumber
      status
      owner
      user
    }
  }
`;

export const UPDATE_ACCOUNT_MUTATION = gql`
  mutation UpdateACCOUNT($id: String!, $status: Int!) {
    updateAccount(bankUpdateInput: { id: $id, status: $status }) {
      _id
      accountNumber
      status
      owner
      user
      bank {
        _id
        name
        logo
      }
    }
  }
`;
