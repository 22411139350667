import { gql } from "@apollo/client";
export const LOGIN_MUTATION = gql`
  mutation SignIn($username: String!, $password: String!) {
    signIn(signInInput: { username: $username, password: $password }) {
      role
      access_token
      refresh_token
    }
  }
`;
