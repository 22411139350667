import { memo } from "react";

const BrandLogo = () => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 1170 1148"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M723.765 696.204V359.888C723.765 346.875 721.972 334.283 718.62 322.346C680.241 338.191 653.222 376.078 653.222 420.297V683.698V704.131V749.992L607.51 749.992L563.49 749.992H517.778V704.131V691.674V420.47C517.778 376.624 491.212 339.002 453.348 322.923C450.099 334.689 448.363 347.085 448.363 359.888V696.204V704.131V749.992H435.948H402.651H306.712H261V704.13V420.47C261 347.369 312.525 286.341 381.127 271.946C375.223 286.279 369.489 304.055 365.298 325.62C330.573 342.954 306.712 378.916 306.712 420.47V704.13L402.651 704.131V359.888C402.651 258.261 484.768 175.876 586.064 175.876C687.361 175.876 769.477 258.261 769.477 359.888V704.131L864.288 704.13V420.297C864.288 379.31 841.073 343.762 807.116 326.167C802.899 304.51 797.146 286.588 791.179 272.054C859.128 286.944 910 347.66 910 420.297V704.13V749.992H864.288L769.477 749.992H723.765V704.131V696.204ZM607.51 704.13V420.297C607.51 357.779 645.195 304.092 699.019 280.853C674.129 245.114 632.818 221.738 586.064 221.738C539.089 221.738 497.607 245.337 472.756 281.363C526.166 304.781 563.49 358.252 563.49 420.47V704.13H607.51Z"
        fill="url(#paint0_linear)"
      />
    </g>
    <path
      d="M586.248 139.802C624.771 139.802 656 108.573 656 70.0495C656 31.5265 624.771 0.297363 586.248 0.297363C547.725 0.297363 516.496 31.5265 516.496 70.0495C516.496 108.573 547.725 139.802 586.248 139.802Z"
      fill="#F9FBFC"
    />
    <path
      d="M636.06 118.873C636.131 118.8 636.202 118.727 636.273 118.654C648.852 105.719 655.833 88.7508 655.995 70.695C656.031 67.8819 655.878 65.0671 655.572 62.2715C653.843 46.5816 646.897 32.0494 635.573 20.7259C625.905 11.0581 613.897 4.58262 600.828 1.81783C591.251 -0.20946 581.247 -0.20946 571.67 1.81783C558.601 4.58262 546.593 11.0581 536.925 20.7259C525.263 32.3881 518.243 47.4541 516.784 63.6828C516.5 66.8136 516.425 69.9605 516.564 73.1025C517.298 90.249 524.202 106.283 536.212 118.641C537.386 119.856 538.612 121.021 539.87 122.147C552.656 133.559 568.972 139.802 586.249 139.802C603.523 139.802 619.836 133.561 632.621 122.154C633.8 121.097 634.954 120.009 636.06 118.873ZM556.805 113.624L534.405 113.372C525.876 103.182 520.289 90.4514 518.962 76.4961L538.629 60.596L556.845 66.5148L567.409 99.0272L556.805 113.624ZM615.652 66.5151L632.973 60.8874L653.44 77.4349C651.956 91.022 646.431 103.411 638.093 113.372L615.693 113.624L605.089 99.0277L615.652 66.5151ZM642.426 32.4977L632.345 58.8219L614.893 64.4921L587.328 44.4656V28.1401L614.29 8.55144C625.737 13.7909 635.476 22.1337 642.426 32.4977ZM558.207 8.55117L585.17 28.1399V44.4653L557.605 64.4921L539.246 58.5272L529.569 33.26C536.55 22.5428 546.482 13.9185 558.207 8.55117ZM566.066 134.565L559.765 114.403C559.689 114.159 559.53 113.96 559.326 113.826L569.121 100.345H603.377L613.163 113.814C612.951 113.948 612.785 114.152 612.706 114.403L606.402 134.575C600.034 136.568 593.265 137.643 586.249 137.643C579.222 137.643 572.443 136.564 566.066 134.565Z"
      fill="black"
    />
    <g filter="url(#filter1_d)">
      <path
        d="M249.699 841.897L264.653 837.822C258.951 845.422 252.75 865.769 246.049 898.862C236.262 948.516 233.976 982.911 239.191 1002.05C241.601 1010.89 245.621 1014.55 251.249 1013.01C253.822 1012.31 256.672 1010.41 259.801 1007.31L260.042 1007.25C260.525 1007.12 260.875 1007.45 261.094 1008.26C261.927 1011.31 258.616 1015.32 251.16 1020.29C243.865 1025.22 237.644 1028.38 232.499 1029.78C221.725 1032.72 214.739 1028.32 211.539 1016.58C205.973 996.157 211.134 950.441 227.021 879.432C222.254 887.295 219.096 892.82 217.546 896.006C206.798 917.42 191.561 952.148 171.833 1000.19C167.636 1010.15 164.617 1016.5 162.774 1019.25C160.932 1021.99 158.001 1023.92 153.981 1025.01C150.122 1026.06 145.291 1026.08 139.488 1025.07C142.105 1018.83 139.864 1002.69 132.764 976.638C125.621 950.427 117.526 927.067 108.482 906.557C105.629 918.908 101.707 945.716 96.7155 986.981C91.7239 1028.25 90.521 1053.62 93.1067 1063.11C93.4135 1064.23 93.6911 1064.94 93.9396 1065.21L71.749 1071.26C74.5161 1045.29 79.1849 1010.76 85.7555 967.684C92.3261 924.607 96.4745 898.946 98.2007 890.702C83.5678 894.69 70.0454 901.485 57.6334 911.087C45.3822 920.645 39.1024 929.612 38.794 937.987C38.7647 938.514 38.5088 938.843 38.0264 938.974C36.74 939.325 35.8119 938.455 35.2422 936.364C35.1546 936.043 35.2061 933.697 35.3969 929.326C35.5876 924.955 36.8602 920.117 39.2145 914.811C41.7297 909.462 45.1144 905.084 49.3687 901.679C56.3714 896.316 65.6617 892.056 77.2394 888.901L107.872 880.552C112.696 879.237 116.694 879.011 119.866 879.874C123.199 880.693 126.26 884.005 129.051 889.808C131.799 895.451 136.635 909.076 143.56 930.682C150.602 952.083 155.328 967.206 157.739 976.05C160.149 984.894 161.632 990.968 162.187 994.272C177.336 959.221 197.246 915.013 221.915 861.648C226.287 852.338 235.548 845.754 249.699 841.897Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M365.696 938.553C366.682 938.387 367.452 939.947 368.005 943.234C368.531 946.357 367.853 950.358 365.97 955.239C364.088 960.119 362.164 964.753 360.199 969.14C358.397 973.5 355.803 978.669 352.416 984.648C349.028 990.627 345.543 995.523 341.96 999.338C332.879 1008.64 323.079 1014.18 312.56 1015.95C302.206 1017.69 293.381 1016.48 286.086 1012.3C278.763 1007.95 274.174 1000.27 272.32 989.262C269.497 972.498 273.045 955.928 282.964 939.554C292.882 923.18 306.059 913.609 322.495 910.841C329.891 909.596 336.139 910.572 341.241 913.769C346.315 916.802 349.309 921.03 350.222 926.454C351.772 935.658 348.662 943.787 340.892 950.842C333.122 957.897 323.321 962.42 311.487 964.413C304.585 965.575 299.337 965.529 295.746 964.275C294.035 973.183 293.719 980.841 294.798 987.251C297.123 1001.06 304.12 1006.98 315.789 1005.01C322.691 1003.85 329.316 1000.03 335.662 993.553C342.144 986.884 347.38 979.834 351.368 972.402C355.492 964.778 359.79 954.167 364.261 940.569C364.724 939.308 365.203 938.636 365.696 938.553ZM336.284 923.477C335.482 918.711 332.779 916.715 328.178 917.49C321.768 918.569 315.283 923.718 308.722 932.935C302.162 942.152 299.296 949.226 300.127 954.157C300.68 957.444 304.08 958.562 310.325 957.51C316.57 956.458 322.55 952.325 328.265 945.109C334.144 937.866 336.817 930.655 336.284 923.477Z"
        fill="url(#paint2_linear)"
      />
      <path
        d="M495.133 827.57C490.044 832.083 483.914 843.98 476.743 863.263C469.721 882.363 463.799 902.445 458.976 923.509C454.138 944.407 452.162 959.418 453.047 968.542C453.562 973.851 455.229 976.368 458.049 976.095C460.869 975.821 464.176 973.658 467.969 969.606C471.928 965.539 474.699 962.172 476.283 959.507C477.866 956.841 479.91 952.875 482.413 947.609C485.066 942.161 487.294 937.507 489.096 933.649C491.048 929.608 492.19 927.572 492.522 927.539C493.683 927.427 494.417 928.946 494.723 932.098C495.12 937.92 489.41 950.698 477.594 970.431C473.694 976.837 468.842 982.918 463.037 988.672C457.232 994.426 451.758 997.552 446.616 998.051C437.824 998.904 432.792 992.778 431.521 979.673C430.587 970.051 431.794 957.459 435.14 941.897C428.974 957.733 421.536 971.683 412.827 983.746C404.117 995.81 394.869 1002.32 385.082 1003.27C373.967 1004.34 367.67 997.253 366.189 981.991C365.063 970.379 366.409 958.359 370.227 945.933C374.211 933.49 380.528 922.663 389.179 913.451C397.814 904.074 407.606 898.854 418.555 897.792C429.669 896.714 437.654 899.623 442.509 906.52C446.269 896.945 449.975 885.953 453.627 873.542C457.445 861.116 459.932 853.088 461.087 849.46C462.392 845.649 463.296 842.882 463.798 841.159C464.467 839.42 465.309 837.747 466.326 836.142C467.925 833.642 469.945 832.023 472.385 831.284C474.975 830.363 479.09 829.629 484.73 829.082C490.52 828.353 493.987 827.849 495.133 827.57ZM441.555 909.627C439.274 906.834 435.812 905.662 431.167 906.113C423.702 906.837 416.524 911.385 409.634 919.756C402.893 927.945 397.683 937.241 394.003 947.645C390.474 957.867 389.071 966.71 389.795 974.175C390.294 979.318 392.534 981.696 396.515 981.31C409.952 980.007 424.965 956.112 441.555 909.627Z"
        fill="url(#paint3_linear)"
      />
      <path
        d="M559.199 858.056L558.2 858.093C554.869 858.219 551.674 859.758 548.617 862.709C545.726 865.653 543.671 868.65 542.452 871.698C538.059 870.196 535.781 867.28 535.617 862.95C535.453 858.62 537.288 854.214 541.122 849.733C544.949 845.085 548.694 842.691 552.358 842.553C556.022 842.414 558.802 843.143 560.697 844.74C562.592 846.336 563.59 848.466 563.691 851.131C563.785 853.629 562.288 855.937 559.199 858.056ZM526.47 892.316L538.97 892.094L541.468 892C538.879 894.099 535.503 899.647 531.34 908.644C527.343 917.635 524.348 924.419 522.352 928.998C520.357 933.577 518.089 939.75 515.547 947.518C513.172 955.28 512.076 961.576 512.258 966.406C512.441 971.236 514.114 973.591 517.279 973.471C519.444 973.39 522.388 971.861 526.112 968.885C529.996 965.736 533.127 962.532 535.505 959.273C537.878 955.847 540.468 951.58 543.277 946.47C546.253 941.354 548.754 936.923 550.781 933.177C552.974 929.425 554.237 927.543 554.57 927.53C555.569 927.492 556.129 929.056 556.248 932.22C556.362 935.218 555.639 938.164 554.081 941.058C548.309 951.617 542.973 960.492 538.075 967.682C533.337 974.699 527.817 980.912 521.517 986.321C515.217 991.729 509.402 994.534 504.073 994.736C494.746 995.088 489.837 988.769 489.346 975.778C488.584 955.626 494.652 933.047 507.55 908.042C513.002 897.829 519.308 892.587 526.47 892.316Z"
        fill="url(#paint4_linear)"
      />
      <path
        d="M670.581 943.167C664.23 953.388 658.409 961.952 653.118 968.858C647.996 975.601 642.139 981.497 635.548 986.546C628.956 991.595 622.994 994.072 617.661 993.977C607.83 993.8 603.011 988.296 603.205 977.465C603.399 966.633 610.038 944.999 623.122 912.561C623.816 911.074 624.177 909.497 624.207 907.83C624.27 904.331 622.052 902.541 617.552 902.46C613.22 902.382 608.862 903.721 604.478 906.476C600.264 909.068 596.361 912.915 592.769 918.018C589.18 922.955 585.922 928.064 582.993 933.345C580.067 938.46 577.294 944.412 574.672 951.199C572.216 957.989 570.197 963.704 568.613 968.343C567.199 972.818 565.695 977.709 564.099 983.015C562.674 988.156 561.873 990.976 561.697 991.473L537.451 991.038C537.514 987.539 545.206 967.59 560.528 931.192C566.835 914.136 570.05 902.192 570.172 895.359C570.202 893.693 569.974 892.439 569.489 891.596L579.988 891.785C585.82 891.889 589.559 892.54 591.205 893.736C592.85 894.933 593.655 896.531 593.619 898.53C593.583 900.53 592.593 904.596 590.65 910.729C604.887 897.649 619.087 891.236 633.252 891.49C642.75 891.66 647.422 896.078 647.266 904.743C647.215 907.576 646.748 910.401 645.864 913.219C644.98 916.037 642.795 921.666 639.31 930.105C631.643 948.637 627.753 961.069 627.64 967.401C627.568 971.401 629.032 973.427 632.031 973.481C634.198 973.52 637.224 972.074 641.111 969.143C645.164 966.215 648.468 963.191 651.025 960.069C653.751 956.784 656.657 952.753 659.743 947.974C662.999 943.031 665.743 938.747 667.975 935.119C670.207 931.492 671.49 929.681 671.823 929.687C672.823 929.705 673.294 931.297 673.237 934.464C673.184 937.463 672.298 940.364 670.581 943.167Z"
        fill="url(#paint5_linear)"
      />
      <path
        d="M775.459 903.999L787.881 905.423L790.369 905.658C784.471 909.621 776.678 920.353 766.99 937.853C757.317 955.187 752.09 968.003 751.306 976.299C750.899 980.613 752.189 982.911 755.175 983.193C764.799 984.102 778.418 971.074 796.03 944.11C796.803 943.012 797.439 942.486 797.937 942.533C798.932 942.627 799.281 944.25 798.984 947.403C798.702 950.389 797.598 953.215 795.672 955.879C772.02 988.299 753.308 1003.86 739.536 1002.56C730.576 1001.71 726.746 994.404 728.046 980.632C729.017 970.344 731.715 960.387 736.14 950.76C714.059 982.492 694.972 997.598 678.876 996.078C667.759 995.029 662.921 986.871 664.363 971.606C665.459 959.991 669.06 948.445 675.166 936.968C681.438 925.507 689.695 916.074 699.936 908.671C710.193 901.101 720.963 897.849 732.246 898.915C737.556 899.416 742.416 901.13 746.827 904.058C751.254 906.82 753.963 910.926 754.955 916.377C757.141 912.733 760.275 909.68 764.357 907.22C768.44 904.759 772.141 903.686 775.459 903.999ZM753.25 918.476C752.092 913.009 748.444 909.986 742.304 909.407C731.353 908.373 720.013 915.003 708.284 929.297C696.571 943.425 690.15 956.463 689.022 968.409C688.537 973.553 690.285 976.313 694.267 976.689C698.581 977.096 703.893 974.919 710.202 970.158C716.526 965.231 722.356 959.336 727.691 952.474C739.514 937.184 748.034 925.851 753.25 918.476Z"
        fill="url(#paint6_linear)"
      />
      <path
        d="M953.027 897.247C947.993 914.453 934.595 925.542 912.834 930.515C907.718 931.606 902.611 932.191 897.513 932.268C922.207 941.732 932.757 956.217 929.163 975.722C926.113 992.277 914.695 1004.58 894.91 1012.63C875.288 1020.7 852.446 1022.34 826.385 1017.54C813.6 1015.19 803.308 1011.26 795.51 1005.75C787.742 1000.08 784.718 992.58 786.439 983.237C788.553 971.763 798.302 952.884 815.686 926.598C833.069 900.313 848.781 878.041 862.822 859.783C865.065 856.807 866.717 854.738 867.778 853.578L858.7 853.177C845.583 852.624 833.223 853.482 821.62 855.751C810.181 858.05 801.988 862.048 797.04 867.746C796.786 868.207 796.331 868.378 795.675 868.257C795.019 868.136 794.767 867.666 794.918 866.847C795.099 865.863 795.302 865.223 795.526 864.925C804.895 849.026 818.204 840.717 835.454 839.997C843.296 839.747 853.527 840.784 866.148 843.11L914.583 852.033C929.498 854.781 940.137 860.046 946.499 867.827C953.025 875.639 955.397 884.38 953.616 894.051L953.027 897.247ZM928.635 886.144C929.817 871.448 919.845 861.645 898.718 856.736C889.172 865.315 875.552 882.211 857.858 907.422C840.164 932.633 828.463 951.068 822.757 962.727C817.08 974.223 813.653 983.166 812.475 989.559C811.327 995.787 812.375 1000.22 815.619 1002.85C818.893 1005.32 824.709 1007.32 833.069 1008.86C848.64 1011.73 863.014 1010.05 876.192 1003.84C889.4 997.46 897.544 985.912 900.624 969.193C902.134 960.998 901.383 953.572 898.373 946.916C895.526 940.291 890.342 935.777 882.819 933.374C872.143 934.288 862.052 933.87 852.546 932.118C849.759 931.605 848.472 930.774 848.683 929.627C849.378 925.857 852.386 923.785 857.709 923.41C863.062 922.871 868.033 923.024 872.622 923.869C877.212 924.715 881.659 925.873 885.964 927.344C897.222 926.029 906.586 921.653 914.057 914.217C921.692 906.811 926.355 898.519 928.046 889.34L928.635 886.144Z"
        fill="url(#paint7_linear)"
      />
      <path
        d="M1046.15 997.805C1047.12 998.063 1047.17 999.802 1046.31 1003.02C1045.49 1006.08 1043.22 1009.44 1039.48 1013.1C1035.74 1016.76 1032.07 1020.18 1028.46 1023.36C1025.01 1026.57 1020.51 1030.2 1014.94 1034.24C1009.38 1038.27 1004.18 1041.28 999.336 1043.27C987.214 1047.96 976 1048.94 965.694 1046.18C955.55 1043.48 948.026 1038.71 943.122 1031.88C938.261 1024.89 937.271 1016 940.152 1005.21C944.538 988.782 954.64 975.178 970.458 964.394C986.276 953.61 1002.24 950.368 1018.34 954.668C1025.58 956.603 1030.87 960.084 1034.18 965.109C1037.54 969.974 1038.51 975.063 1037.09 980.377C1034.68 989.394 1028.48 995.501 1018.48 998.697C1008.49 1001.89 997.692 1001.94 986.099 998.846C979.336 997.04 974.58 994.821 971.833 992.19C966.581 999.585 963.116 1006.42 961.439 1012.7C957.827 1026.23 961.737 1034.52 973.17 1037.57C979.933 1039.38 987.545 1038.65 996.006 1035.39C1004.67 1032.01 1012.36 1027.77 1019.07 1022.66C1025.99 1017.43 1034.3 1009.56 1044.01 999.044C1044.95 998.089 1045.67 997.676 1046.15 997.805ZM1025.64 971.886C1026.89 967.216 1025.26 964.28 1020.75 963.076C1014.47 961.398 1006.43 963.392 996.641 969.058C986.848 974.723 981.307 979.971 980.016 984.801C979.156 988.022 981.786 990.449 987.905 992.083C994.024 993.717 1001.18 992.437 1009.37 988.242C1017.73 984.09 1023.15 978.638 1025.64 971.886Z"
        fill="url(#paint8_linear)"
      />
      <path
        d="M1141.21 947.545C1131.7 950.42 1118.51 961.391 1101.65 980.457L1125.67 988.236L1125.83 988.551C1125.57 989.344 1124.24 990.488 1121.83 991.985C1119.47 993.323 1117.65 993.786 1116.39 993.376L1095.93 986.752C1073.34 1013.77 1060.61 1031.72 1057.73 1040.6C1055.78 1046.63 1056.71 1050.26 1060.52 1051.49C1066.06 1053.29 1074.11 1050.64 1084.64 1043.54C1087.05 1042.04 1090.48 1039.29 1094.93 1035.3C1106.02 1025.93 1111.72 1021.3 1112.04 1021.4C1112.99 1021.71 1113.06 1023.13 1112.24 1025.67C1110.13 1031.64 1099.75 1041.24 1081.1 1054.48C1074.97 1058.8 1068.09 1062.44 1060.48 1065.41C1052.86 1068.37 1046.59 1069.06 1041.68 1067.46C1032.64 1064.54 1030.17 1056.73 1034.28 1044.05C1037.72 1033.42 1044.21 1022.04 1053.75 1009.88L1053.83 1009.65C1049.02 1014.22 1045.07 1017.5 1041.98 1019.47C1039.04 1021.5 1037.42 1022.46 1037.1 1022.36C1036.47 1022.16 1036.46 1021.1 1037.07 1019.2C1037.74 1017.14 1039.82 1014.22 1043.32 1010.45C1046.98 1006.73 1051.79 1001.89 1057.74 995.935C1063.86 990.032 1069.06 984.532 1073.34 979.436L1057.4 974.276C1056.92 974.122 1056.79 973.728 1056.99 973.093C1057.25 972.301 1058.22 971.212 1059.89 969.827C1061.73 968.494 1062.96 967.93 1063.59 968.135L1081.67 973.988C1101.97 950.254 1117.98 940.286 1129.72 944.086C1136.54 946.293 1140.37 947.446 1141.21 947.545Z"
        fill="url(#paint9_linear)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="256"
        y="167.876"
        width="673"
        height="598.115"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="7" dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.63 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_d"
        x="35.2012"
        y="817.57"
        width="1134.01"
        height="271.692"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="14" dy="4" />
        <feGaussianBlur stdDeviation="7" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.61 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="585.5"
        y1="175.876"
        x2="585.5"
        y2="749.992"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F37834" />
        <stop offset="1" stopColor="#F7D466" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="101.305"
        y1="816.525"
        x2="183.61"
        y2="1118.51"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F4BA54" />
        <stop offset="1" stopColor="#F4BA54" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="273.858"
        y1="778.581"
        x2="325.831"
        y2="1087.24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F4BA54" />
        <stop offset="1" stopColor="#F4BA54" />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="397.614"
        y1="762.18"
        x2="427.833"
        y2="1073.72"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F4BA54" />
        <stop offset="1" stopColor="#F4BA54" />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="502.27"
        y1="755.133"
        x2="514.094"
        y2="1067.91"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F4BA54" />
        <stop offset="1" stopColor="#F4BA54" />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="601.459"
        y1="754.148"
        x2="595.847"
        y2="1067.1"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F4BA54" />
        <stop offset="1" stopColor="#F4BA54" />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="736.91"
        y1="761.746"
        x2="707.489"
        y2="1073.36"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F4BA54" />
        <stop offset="1" stopColor="#F4BA54" />
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1="892.159"
        y1="783.333"
        x2="835.448"
        y2="1091.15"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F4BA54" />
        <stop offset="1" stopColor="#F4BA54" />
      </linearGradient>
      <linearGradient
        id="paint8_linear"
        x1="1028.95"
        y1="814.149"
        x2="948.196"
        y2="1116.55"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F4BA54" />
        <stop offset="1" stopColor="#F4BA54" />
      </linearGradient>
      <linearGradient
        id="paint9_linear"
        x1="1118.07"
        y1="840.459"
        x2="1021.65"
        y2="1138.24"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F4BA54" />
        <stop offset="1" stopColor="#F4BA54" />
      </linearGradient>
    </defs>
  </svg>
);

export default memo(BrandLogo);
