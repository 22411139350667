import React, { Fragment, Suspense, createElement, lazy } from "react";
import { Controller } from "react-hook-form";
// import Input from "../atoms/Input";

const InputGroup = ({
  id,
  name,
  type,
  error,
  label,
  onBlur,
  onFocus,
  classes,
  options,
  control,
  setValue,
  readOnly,
  realName,
  register,
  getValues,
  formState,
  toggleModal,
  placeholder,
  selectedItem,
  component,
  title,
  isMulti,
  CustomOption,
  fileRef,
}) => {
  const InputComponent = component
    ? lazy(async () => await component)
    : Fragment;
  return (
    <>
      {title ? <p className="text-sm mb-3">{title}</p> : null}
      <div className={`${classes ? classes : "mb-3"}`}>
        {
          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange, onFocus, onBlur } }) => {
              return (
                <Suspense fallback={<></>}>
                  <InputComponent
                    id={id}
                    name={name}
                    type={type}
                    value={value}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    readOnly={readOnly}
                    onChange={onChange}
                    labelText={label}
                    placeholder={placeholder}
                    isMulti={isMulti}
                    CustomOption={CustomOption}
                    {...(type === "file" ? { fileRef: fileRef } : {})}
                    {...(options ? { options } : {})}
                  />
                </Suspense>
              );
            }}
          />
        }
        {error?.message && (
          <span className="text-rose-600 text-sm">{error?.message}</span>
        )}
      </div>
    </>
  );
};

export default InputGroup;
