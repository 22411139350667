import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  site: {},
};

const siteConfigSlice = createSlice({
  name: "siteConfig",
  initialState,
  reducers: {
    setSiteConfigs: (state, action) => {
      state.site = action.payload || {};
    },
    decrement: (state) => {
      state.site.gas -= state.site.requestPrice;
    },
    // create.preparedReducer(
    //   (payload) => payload,
    //   (state, action) => {
    //     state.site = action.payload;
    //   }
    // ),
  },
  selectors: {
    getSiteConfigs: (sliceState) => sliceState.site,
  },
});

export const { setSiteConfigs, decrement } = siteConfigSlice.actions;
// export const { getSettings } = settingsSlice.selectors;

export default siteConfigSlice.reducer;
