import AddSettingsModal from "components/organisms/modals/AddSettingsModal";
import AddBankModal from "components/organisms/modals/AddBankModal";
import AddUserModal from "components/organisms/modals/AddUserModal";
import DeleteItemModal from "components/organisms/modals/DeleteItemModal";
import SelectBankModal from "components/organisms/modals/SelectBankModal";
import EditSettingsModal from "components/organisms/modals/EditSettingsModal";
import LowGasModal from "components/organisms/modals/LowGasModal";

export const MODAL_TYPES = {
  CREATE_MODAL: "CREATE_MODAL",
  UPDATE_MODAL: "UPDATE_MODAL",
  DELETE_MODAL: "DELETE_MODAL",
  SELECT_BANK_MODAL: "SELECT_BANK_MODAL",
  ADD_BANK_MODAL: "ADD_BANK_MODAL",
  ADD_SETTINGS_MODAL: "ADD_SETTINGS_MODAL",
  EDIT_SETTINGS_MODAL: "EDIT_SETTINGS_MODAL",
  ADD_USER_MODAL: "ADD_USER_MODAL",
  LOW_GAS_MODAL: "LOW_GAS_MODAL",
};

export const MODAL_COMPONENTS = {
  [MODAL_TYPES.CREATE_MODAL]: <div></div>,
  [MODAL_TYPES.UPDATE_MODAL]: <div></div>,
  [MODAL_TYPES.SELECT_BANK_MODAL]: SelectBankModal,
  [MODAL_TYPES.ADD_BANK_MODAL]: AddBankModal,
  [MODAL_TYPES.ADD_USER_MODAL]: AddUserModal,
  [MODAL_TYPES.ADD_SETTINGS_MODAL]: AddSettingsModal,
  [MODAL_TYPES.EDIT_SETTINGS_MODAL]: EditSettingsModal,
  [MODAL_TYPES.DELETE_MODAL]: DeleteItemModal,
  [MODAL_TYPES.LOW_GAS_MODAL]: LowGasModal,
};
