const defaultOption = {
  // Don't cache separate results based on
  // any of this field's arguments.
  keyArgs: false,

  // Concatenate the incoming list items with
  // the existing list items.
  merge(existing = {}, incoming) {
    const existingData = existing?.data || [];
    const newData = incoming?.data || [];
    return {
      ...incoming,
      data: [...existingData, ...newData],
    };
  },
};

export const mergeOptionsForPaginations = {
  statisticDeposits: defaultOption,
  deposits: defaultOption,
  accounts: defaultOption,
  configs: defaultOption,
  users: defaultOption,
};
