import Client from "./clients";
import Types from "../utils/constants/repositoryTypes";
import MainRepository from "./MainRepository";
const resource = Types.WITHDRAW;

export default {
    ...MainRepository(resource),
    selectBank: (id, payload) => {
        return Client.post(`${resource}/${id}/select-bank`, {...payload});
    }
}