import { Fragment, useCallback } from "react";
import { useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { useGlobalModalContext } from "../GlobalModal";
import AddUserFormTemplate from "components/organisms/Forms/AddUserFormTemplate";
import { CREATE_USER_MUTATION } from "utils/gql/mutations/user.mutation";
import { GET_USERS_QUERY } from "utils/gql/queries/user.query";

const AddUserModal = () => {
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store;
  const { title, confirmBtn } = modalProps || {};

  const [create] = useMutation(CREATE_USER_MUTATION);

  const onSubmit = useCallback(async (data) => {
    await create({
      variables: {
        username: data.username,
        name: data.name,
        email: data.email,
        role: data.permission.value,
      },
      refetchQueries: [GET_USERS_QUERY],
    });
    hideModal();
  }, []);

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={hideModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 mb-5"
                >
                  {title}
                </Dialog.Title>
                <div className="mt-1">
                  <AddUserFormTemplate
                    buttonContent={confirmBtn}
                    onSubmit={onSubmit}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddUserModal;
