import { useState } from "react"
import useDebounce from "./useDebounce";

const useFilter = () => {
    const [filterOptions, setFilterOptions] = useState([]);
    const {debounceValue, setDebounceValue, isLoading} = useDebounce(filterOptions);

    return {setFilterOptions, filterOptions: debounceValue || filterOptions, isLoading};
}

export default useFilter;
