import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useGlobalModalContext } from "../GlobalModal";

const LowGasModal = () => {
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store;
  const { title } = modalProps || {};

  const handleModalToggle = () => {
    hideModal();
  };

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleModalToggle}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {title || ""}
                </Dialog.Title>
                <div className="mt-1 text-center">
                  <h1 className="text-xl">Low gas!</h1>
                  <p className="mt-4 text-neutral-500">
                    Please refill your balance to have access special actions!
                  </p>
                </div>

                <div className="mt-4 text-center flex items-center gap-5 justify-center">
                  <button
                    type="button"
                    className="inline-flex w-full mt-1 justify-center rounded-md border border-transparent bg-primary-100 px-4 py-2 text-sm font-medium text-primary-900 hover:bg-primary-200 border-primary-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary-500 focus-visible:ring-offset-2"
                    onClick={handleModalToggle}
                  >
                    Okay
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default LowGasModal;
