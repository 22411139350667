import { Fragment, useCallback, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useGlobalModalContext } from "../GlobalModal";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_BANK_MUTATION } from "../../../utils/gql/mutations/bank.mutation";
import { GET_BANKS_QUERY } from "utils/gql/queries";
import AddBankFormTemplate from "../Forms/AddBankFormTemplate";
import { compressor } from "utils/helpers";

const AddBankModal = () => {
  const { hideModal, store } = useGlobalModalContext();
  const fileRef = useRef();
  const { modalProps } = store;
  const { title, confirmBtn } = modalProps || {};

  const [create] = useMutation(CREATE_BANK_MUTATION);
  const { data } = useQuery(GET_BANKS_QUERY, {
    variables: { filter: {} },
  });

  const onSubmit = useCallback(async (data) => {
    const [file] = fileRef?.current?.files;
    const compressedFile = await compressor(file);
    await create({
      variables: {
        bank: data?.bank?.value || "",
        name: data.bank_name,
        accountNumber: data.bank_acc_num,
        logo: compressedFile,
        owner: data.bank_acc_holder,
      },
      headers: {
        "content-type": "multipart/form-data",
      },
      refetchQueries: [GET_BANKS_QUERY],
    });
    hideModal();
  }, []);

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={hideModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 mb-5"
                >
                  {title}
                </Dialog.Title>
                <div className="mt-1">
                  <AddBankFormTemplate
                    onSubmit={onSubmit}
                    buttonContent={confirmBtn}
                    fileRef={fileRef}
                    options={{
                      bank:
                        data?.banks?.map((option) => ({
                          value: option._id,
                          label: option.name,
                        })) || [],
                    }}
                  />
                  {/* <FormInputsGenerate
                    onSubmit={onSubmit}
                    buttonContent={confirmBtn}
                    formSchema={addBankSchema}
                    inputs={ADD_BANK_INPUTS}
                    options={{
                      bank:
                        data?.banks?.map((option) => ({
                          value: option._id,
                          label: option.name,
                        })) || [],
                    }}
                  /> */}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddBankModal;
