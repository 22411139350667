export const TRANSACTIONS_TABLE_HEADERS = [
  "Transaction ID",
  "External ID",
  "Player ID",
  "Type",
  "Amount",
  "Bank Name Received",
  "Account Receiving",
  "Bank Name Sent",
  "Account Numb. Sent",
  "Bank Reference ID",
  "Bank Ref. Desc.",
  "First Name",
  "Last Name",
  "Date/Time Scroped",
  "Date/Time Requested",
  "Date/Time Updated",
  "Bank Status",
  "Status Sent",
  "Block",
  "Resend",
];

export const TRANSACTION_FILTER_INPUTS = [
  {
    name: "transaction_id",
    type: "text",
    placeholder: "Transaction ID",
    label: "Transaction ID",
    component: import("components/atoms/Input"),
  },
  {
    name: "external_id",
    type: "text",
    placeholder: "External ID",
    label: "External ID",
    component: import("components/atoms/Input"),
  },
  {
    name: "player_id",
    type: "text",
    placeholder: "Player ID",
    label: "Player ID",
    component: import("components/atoms/Input"),
  },
  {
    name: "type",
    type: "text",
    placeholder: "Type",
    label: "Type",
    component: import("components/atoms/Input"),
  },
  {
    name: "amount",
    type: "number",
    placeholder: "Amount",
    label: "Amount",
    component: import("components/atoms/Input"),
  },
  {
    name: "bank_name_received",
    type: "text",
    placeholder: "Bank Name Received",
    label: "Bank Name Received",
    component: import("components/atoms/Input"),
  },
  {
    name: "account_receiving",
    type: "text",
    placeholder: "Account Receiving",
    label: "Account Receiving",
    component: import("components/atoms/Input"),
  },
  {
    name: "bank_name_sent",
    type: "text",
    placeholder: "Bank Name Sent",
    label: "Bank Name Sent",
    component: import("components/atoms/Input"),
  },
  {
    name: "account_number_sent",
    type: "number",
    placeholder: "Account Numb. Sent",
    label: "Account Numb. Sent",
    component: import("components/atoms/Input"),
  },
  {
    name: "bank_reference_id",
    type: "text",
    placeholder: "Bank Reference ID",
    label: "Bank Reference ID",
    component: import("components/atoms/Input"),
  },
  {
    name: "bank_ref_description",
    type: "text",
    placeholder: "Bank Ref. Desc.",
    label: "Bank Ref. Desc.",
    component: import("components/atoms/Input"),
  },
  {
    name: "first_name",
    type: "text",
    placeholder: "First Name",
    label: "First Name",
    component: import("components/atoms/Input"),
  },
  {
    name: "last_name",
    type: "text",
    placeholder: "Lase Name",
    label: "Lase Name",
    component: import("components/atoms/Input"),
  },
];
