import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  auth: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.auth = action.payload || {};
    },
  },
  selectors: {
    getAuth: (sliceState) => sliceState.auth,
  },
});

export const { setAuth } = authSlice.actions;
export default authSlice.reducer;
