import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  withdrawals: [],
};

const withdrawalSlice = createSlice({
  name: "withdrawal",
  initialState,
  reducers: (create) => ({
    setWithdrawals: create.preparedReducer(
      (payload) => payload,
      (state, action) => {
        state.withdrawals = action.payload;
      }
    ),
  }),
  selectors: {
    getWithdrawals: (sliceState) => sliceState.withdrawals,
  },
});

export const { setWithdrawals } = withdrawalSlice.actions;
// export const { getWithdrawals } = withdrawalSlice.selectors;
export default withdrawalSlice.reducer;

// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//     withdrawals: [],
// }

// const {reducer, actions: { setWithdrawals }} = createSlice({
//     name: 'withdrawal',
//     initialState,
//     reducers: {
//         setWithdrawals: (state, payload) => ({
//             ...state,
//             ...payload,
//         })
//     }
// });

// const getWithdrawals = () => async (dispatch) => {
//     try {
//         dispatch(setWithdrawals());
//     } catch (e) {
//         console.error(e);
//     }
// };

// export {
//     getWithdrawals,
//     setWithdrawals,
// }

// export default reducer;
