import Client from "./clients";
import Types from "../utils/constants/repositoryTypes";
import MainRepository from "./MainRepository";
const resource = Types.USER;

export default {
    ...MainRepository(resource),
    post: (payload = {}) => {
        return Client.post(`${resource}/`, {...payload});
    },
    delete: (id) => {
        return Client.delete(`${resource}/${id}`);
    },
    update: (id, payload = {}) => {
        return Client.put(`${resource}/${id}`, {...payload});
    },
    sendPass: (id, payload = {}) => {
        return Client.update(`${resource}/${id}`, {...payload});
    },
}