import RepositoryFactory from "../repositories/RepositoryFactory";
import Types from "../utils/constants/repositoryTypes";

const api = RepositoryFactory.get(Types.WITHDRAW);

const WithdrawService = (() => {
    let instance = null;
     const createInstance = () => {
        return {
            get: async (payload) => {
                return api.get(payload);
            },
            selectBank: async (id, payload) => {
                return api.selectBank(id, payload);
            },
        }
     }

     return {
        getInstance: () => {
            if(!instance) {
                instance = createInstance();
            }

            return instance;
        }
     }
})();

export default WithdrawService.getInstance();
