import { Fragment, useCallback } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import { useGlobalModalContext } from "components/organisms/GlobalModal";
import { GET_ACCOUNTS_FOR_SERRINGS_FORM_QUERY } from "utils/gql/queries";
import { UPDATE_SETTINGS_MUTATION } from "utils/gql/mutations/settings.mutation";
import {
  GET_SETTINGS_QUERY,
  GET_SETTING_QUERY,
} from "utils/gql/queries/settings.query";
import AddSettingsFormTemplate from "../Forms/AddSettingsFormTemplate";

const EditSettingsModal = () => {
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store;
  const { title, confirmBtn, id } = modalProps || {};

  const [update] = useMutation(UPDATE_SETTINGS_MUTATION);
  const { loading: accountLoading, data } = useQuery(
    GET_ACCOUNTS_FOR_SERRINGS_FORM_QUERY,
    {
      variables: { filter: {} },
    }
  );
  const { loading, data: config } = useQuery(GET_SETTING_QUERY, {
    variables: { id },
  });

  const onSubmit = useCallback(async (data) => {
    await update({
      variables: {
        ...data,
        id,
        accounts: data.banks.map((bank) => bank.value),
        banks: data.banks.map((bank) => bank.bank),
        players: data.players.map((player) => player.value),
      },
      refetchQueries: [GET_SETTINGS_QUERY],
    });
    hideModal();
  }, []);

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={hideModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 mb-5"
                >
                  {title}
                </Dialog.Title>
                <div className="mt-1">
                  {!loading && !accountLoading ? (
                    <AddSettingsFormTemplate
                      onSubmit={onSubmit}
                      buttonContent={confirmBtn}
                      defaultValues={{
                        time: config?.config?.time,
                        type: config?.config?.type,
                        players: config?.config?.playerId?.map((player) => ({
                          label: player,
                          value: player,
                        })),
                        banks: config?.config?.bankId?.map((id) => {
                          const account = data?.accounts?.find(
                            (item) => item?.bank?._id === id
                          );
                          return {
                            value: id,
                            bank: account?.bank?._id,
                            label: account?.bank?.name,
                            account: account?.accountNumber,
                          };
                        }),
                      }}
                      options={{
                        banks:
                          data?.accounts?.map((option) => ({
                            value: option._id,
                            bank: option?.bank?._id,
                            label: option?.bank?.name,
                            account: option?.accountNumber,
                          })) || [],
                        players: [
                          ...config?.config?.playerId?.map((player) => ({
                            label: player,
                            value: player,
                          })),
                        ],
                      }}
                    />
                  ) : null}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EditSettingsModal;
