import {
  // createEntityAdapter,
  // createSelector,
  createSlice,
} from "@reduxjs/toolkit";
// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
// import { gql } from "graphql-request";
// import { GET_ACCOUNTS_QUERY, GET_BANKS_QUERY } from "utils/gql/queries";
// import { graphQlBaseQuery } from "utils/helpers/gqlRequest";
// import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
// import { client } from "repositories/clients/apollo";

// const BanksAdapter = createEntityAdapter();

// const initialState = BanksAdapter.getInitialState();
const initialState = {
  isLoading: false,
  accounts: [],
  banks: [],
};

export const bankSlice = createSlice({
  name: "banks",
  initialState,
  reducers: (create) => ({
    setAccounts: create.preparedReducer(
      (payload) => payload,
      (state, action) => {
        state.accounts = action.payload;
      }
    ),
  }),
  selectors: {
    getAccounts: (sliceState) => sliceState.accounts,
  },
});

export const { setAccounts } = bankSlice.actions;
// export const { getAccounts } = bankSlice.selectors;
export default bankSlice.reducer;
// export const bankSlice = createApi({
//   reducerPath: "banks",
//   baseQuery:
//     // graphqlRequestBaseQuery({
//     //   client,
//     //   prepareHeaders: (headers, { getState }) => {
//     //     const state = getState();
//     //     // console.log(state, "OOOOO");

//     //     return headers;
//     //   },
//     // }),

//     graphQlBaseQuery({ baseUrl: "" }),
//   endpoints: (builder) => ({
//     getAccounts: builder.query({
//       query: ({ filter }) => ({
//         document: GET_ACCOUNTS_QUERY,
//         variables: { filter },
//       }),
//     }),
//     getBanks: builder.query({
//       query: () => ({
//         document: GET_BANKS_QUERY,
//       }),
//     }),
//   }),
// });

// export const { useGetBanksQuery, useGetAccountsQuery } = bankSlice;
// export const selectBankResult = bankSlice.endpoints.getBanks.select();

// const selectBanksData = createSelector(
//   selectBankResult,
//   (banksResult) => banksResult.data
// );

// export const {
//   selectAll: selectAllBanks,
//   selectById: selectBankById,
//   selectIds: selectBankIds,
// } = BanksAdapter.getSelectors(
//   (state) => selectBanksData(state) ?? initialState
// );
