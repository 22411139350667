import styles from '../../styles/components/GridTable.module.scss'

const THeader = ({headers}) => {
    return (
        <div className={styles.header}>
            {headers.map((item, index) => (
                <div key={index}>{item}</div>
            ))}
        </div>
    );
}

export default THeader;