import CustomAccountNumberOption from "components/atoms/selectBox/bank-account-options";

export const SETTINGS_HEADERS = [
  "Config ID",
  "Bank ID (s)",
  "Status",
  "Action D/T",
  "User",
  "Action",
];

export const ADD_SETTINGS_INPUTS = [
  {
    id: "players",
    name: "players",
    type: "select",
    label: "Players",
    placeholder: "Players",
    component: import("components/atoms/multi-select"),
    isMulti: true,
  },
  {
    id: "banks",
    name: "banks",
    type: "select",
    label: "Bank ID (s)",
    placeholder: "Bank ID (s)",
    component: import("components/atoms/select"),
    isMulti: true,
    customOption: CustomAccountNumberOption,
    options: [],
  },
  {
    id: "type",
    name: "type",
    type: "text",
    label: "Type",
    placeholder: "Type",
    component: import("components/atoms/Input"),
  },
  {
    id: "time",
    name: "time",
    type: "text",
    label: "Time",
    placeholder: "Time",
    component: import("components/atoms/date-range"),
  },
];
