import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuth } from "store/actions/auth";
import { selectAuth } from "store/selectors";
import { AUTH_USER_QUERY } from "utils/gql/queries";

const useAuth = () => {
  const dispatch = useDispatch();
  const auth = useSelector(selectAuth);

  const [getAuth] = useLazyQuery(AUTH_USER_QUERY);

  useEffect(() => {
    if (!auth?.auth && localStorage.getItem("user")) {
      getAuth().then((user) => {
        dispatch(setAuth(user?.data?.currentUser));
      });
    }
  }, [auth?.auth]);

  return { auth, isLoggedIn: localStorage.getItem("user") };

  //   const _user = localStorage.getItem("user");
  //   const user = _user && JSON.parse(_user);

  //   if (user) {
  //     return { auth: true, role: user.role };
  //   }

  //   return { auth: false, role: null };
};

export default useAuth;
