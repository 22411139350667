export const USER_ROLES = {
  ALL: "all",
  VIEW: "view",
  BANK: "bank",
  CONFIG: "config",
};

export const USER_ROLE_OPTIONS = [
  // { label: "User", value: "user" },
  { label: "All", value: "all" },
  { label: "View", value: "view" },
  { label: "Bank", value: "bank" },
  { label: "Config", value: "config" },
  // { label: "Admin", value: "admin" },
  // { label: "Super", value: "super" },
];

export const ADD_USER_INPUTS = [
  {
    id: "username",
    name: "username",
    type: "text",
    label: "Username",
    placeholder: "Enter username",
    component: import("components/atoms/Input"),
  },
  {
    id: "name",
    name: "name",
    type: "text",
    label: "Name",
    placeholder: "Enter name",
    component: import("components/atoms/Input"),
  },
  {
    id: "email",
    name: "email",
    type: "text",
    label: "Email address",
    placeholder: "Email address",
    component: import("components/atoms/Input"),
  },
  {
    id: "permission",
    name: "permission",
    type: "select",
    label: "Permission",
    placeholder: "Permission",
    component: import("components/atoms/select"),
    options: USER_ROLE_OPTIONS,
  },
];

export const USERS_TABLE_HEADERS = [
  "ID",
  "Email",
  "Name",
  "Status",
  "Permission",
  "Username",
  //   "Un/Lock",
  //   "Send Pass.",
  "Created At",
  "Updated At",
  "Actions",
];

export const USER_LOGIN_INPUTS = [
  {
    id: "username",
    name: "username",
    type: "text",
    label: "Username",
    placeholder: "Username",
    component: import("components/atoms/Input"),
  },
  {
    id: "password",
    name: "password",
    type: "password",
    label: "Password",
    placeholder: "Password",
    component: import("components/atoms/Input"),
  },
];

export const USERS_FILTER_INPUTS = [
  {
    name: "email",
    type: "text",
    placeholder: "Email address",
    label: "Email address",
    component: import("components/atoms/Input"),
  },
  {
    name: "permission",
    type: "text",
    placeholder: "Permission",
    label: "Permission",
    component: import("components/atoms/Input"),
  },
];
