import * as Yup from "yup";

export const addSettingsSchema = Yup.object()
  .shape({
    banks: Yup.mixed().required("Please chose the bank(s)"),
    players: Yup.mixed().required("Please chose the player(s)"),
    type: Yup.string().trim().required("Type is required"),
    time: Yup.mixed().required("Time is required"),
  })
  .required();
