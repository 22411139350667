import axios from 'axios';

/** Default config for axios instance */
let config = {
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
};

/** Creating the instance for axios */
const axiosClient = axios.create(config);

/** Auth token interceptors */
const authInterceptor = (config) => {
  /** TODO: Add auth token */

  return config;
};

/** logger interceptors */
const loggerInterceptor = (config) => {
  /** TODO */

  return config;
};

/** Adding the request interceptors */
axiosClient.interceptors.request.use(authInterceptor);
axiosClient.interceptors.request.use(loggerInterceptor);

/** Adding the response interceptors */
axiosClient.interceptors.response.use(
  (response) => {
    /** TODO: Add any response interceptors */
    return response;
  },
  async (error) => {
    /** TODO: Do something with response error */
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const accessToken = await axiosClient.post('/auth/refresh');
        localStorage.setItem('user', JSON.stringify(accessToken.data.user));
        return axiosClient(originalRequest);
      } catch (e) {
        axiosClient.post('/auth/logout')
        localStorage.removeItem('user');
        window.location.href = '/login';
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  },
);

export default axiosClient;