import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import auth from "./actions/auth";
import site from "./actions/siteConfig";
import settings from "./actions/settings";
import bank from "./actions/bank";
import transaction from "./actions/transaction";
import deposit from "./actions/deposit";
import withdrawal from "./actions/withdrawal";

export const store = configureStore({
  reducer: {
    auth: auth,
    site: site,
    settings: settings,
    bank: bank,
    deposit: deposit,
    withdrawal: withdrawal,
    transaction: transaction,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

setupListeners(store.dispatch);
