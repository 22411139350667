import { gql } from "@apollo/client";
export const CREATE_SETTINGS_MUTATION = gql`
  mutation CreateConfigs(
    $accounts: [String!]!
    $banks: [String!]!
    $players: [String!]!
    $time: [String!]!
    $type: String!
  ) {
    createConfig(
      configCreateInput: {
        accounts: $accounts
        banks: $banks
        players: $players
        time: $time
        type: $type
      }
    ) {
      _id
      status
      user
      actionedAt
    }
  }
`;

export const UPDATE_SETTINGS_MUTATION = gql`
  mutation UpdateConfig(
    $id: String!
    $status: Int
    $banks: [String!]
    $accounts: [String!]
    $players: [String!]
    $time: [String!]
    $type: String
  ) {
    updateConfig(
      configUpdateInput: {
        id: $id
        status: $status
        accounts: $accounts
        banks: $banks
        players: $players
        time: $time
        type: $type
      }
    ) {
      _id
      status
      user
      actionedAt
    }
  }
`;

// export const UPDATE_ACCOUNT_MUTATION = gql`
//   mutation UpdateACCOUNT($id: String!, $status: Int!) {
//     updateAccount(bankUpdateInput: { id: $id, status: $status }) {
//       _id
//       accountNumber
//       status
//       owner
//       user
//       bank {
//         _id
//         name
//         logo
//       }
//     }
//   }
// `;
