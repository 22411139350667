import { Navigate } from "react-router-dom";

import UserLayout from "../layouts/UserLayout";
import Deposits from "../pages/deposits";
import Login from "../pages/login";
import Settings from "../pages/settings";
import BankSettings from "../pages/bank-settings";
import Transactions from "../pages/transactions";
import Dashboard from "../pages/dashboard";
import Withdrawals from "../pages/withdrawals";
import Users from "../pages/users";
import AuthLayout from "layouts/AuthLayout";

const routes = (isLoggedIn) => [
  {
    path: "/",
    element: isLoggedIn ? <UserLayout /> : <Navigate to="/login" />,
    children: [
      { path: "", element: <Navigate to="/dashboard" /> },
      { path: "dashboard", element: <Dashboard /> },
      { path: "deposits", element: <Deposits /> },
      { path: "withdrawals", element: <Withdrawals /> },
      { path: "transactions", element: <Transactions /> },
      { path: "users", element: <Users /> },
      { path: "settings", element: <Settings /> },
      { path: "bank-settings", element: <BankSettings /> },
      // {
      //   path: 'users',
      //   element: <Outlet />,
      //   children: [
      //     { path: '', element: <Users /> },
      //     { path: 'add', element: <AddUser /> },
      //   ],
      // },
      // {
      //   path: 'inquiries',
      //   element: <Outlet />,
      //   children: [
      //     { path: '', element: <Inquiry /> },
      //     { path: 'add', element: <AddInquiry /> },
      //   ],
      // },
    ],
  },
  {
    path: "/",
    element: !isLoggedIn ? <AuthLayout /> : <Navigate to="/dashboard" />,
    children: [
      { path: "login", element: <Login /> },
      { path: "", element: <Navigate to="/login" /> },
    ],
  },
];

export default routes;
